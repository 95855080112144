import React, { useState, useEffect } from "react";
import RoleItem from "./components/RoleItem";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import RoleForm from "./components/RoleForm";
import JumboCustomLoader from "@jumbo/components/JumboLoader";
import { useFetchAllRoles } from "./query/useFetchAllRoles";
import { useRoleApiActions } from "./query/useRoleApiActions";

const Settings = () => {
  const { showDialog, hideDialog } = useJumboDialog();

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    data: rolesData,
    isLoading: isLoadingRole,
    refetch: refetchRole,
  } = useFetchAllRoles();



  useEffect(() => {
    if (hideDialog) {
      refetchRole();
    }
  }, [hideDialog]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refetchRole();
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    refetchRole();
  };

  // const handleUpdateRole = (roleId, updatedData) => {
  //   const updatedRoles = roles.map((role) =>
  //     role.id === roleId ? { ...role, ...updatedData } : role
  //   );
  //   setRoles(updatedRoles);
  // };

  // const handleDeleteRole = (roleId) => {
  //   showDialog({
  //     variant: "confirm",
  //     title: "Are you sure about deleting this contact?",
  //     content: "You won't be able to recover this contact later",
  //     onYes: () => {
  //       tryDeleteRole?.(roleId);

  //       hideDialog();
  //     },
  //     onNo: hideDialog,
  //   });
  // };

  const showAddLabelDialog = React.useCallback(() => {
    showDialog({
      title: <Typography variant="h1">Add New Role</Typography>,
      content: (
        <RoleForm
          showDialog={showDialog}
          hideDialog={() => {
            hideDialog();
            setTimeout(() => {
              refetchRole(); // Refetch roles after dialog closes
            }, 1000); // Optional delay for smoother transition
          }}
          refetchRole={refetchRole}
        />
      ),
      maxWidth: "lg",
      fullWidth: true,
    });
  }, [refetchRole, showDialog, hideDialog]);

  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={2}
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        marginBottom={3}
      >
        <Typography variant={"h2"} mb={3}>
          Roles
        </Typography>
        <Button
          startIcon={<AddIcon />}
          sx={{
            padding: "10px 16px 10px 16px",
            gap: "8px",
            backgroundColor: "#371B65",
            textTransform: "none",
            fontFamily: "Roboto",
            fontSize: { lg: "14px", md: "12px", xs: "10px" },
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.05em",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={showAddLabelDialog}
        >
          ADD ROLE
        </Button>
      </Stack>

      {isLoadingRole ? (
        <JumboCustomLoader isList={true} isCustom={false} />
      ) : Array.isArray(rolesData) && rolesData.length > 0 ? (
        rolesData.map((user, index) => <RoleItem key={index} user={user} refetchRole={refetchRole} />)
      ) : (
        <Typography>No roles available</Typography>
      )}

      <TablePagination
        component="div"
        count={rolesData?.length || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default Settings;
