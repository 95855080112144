import Div from "@jumbo/shared/Div";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useRoleForm from "../hooks/useRoleForm";
import { useFetchAllControl } from "../query/useFetchAllControl";
import { useRoleApiActions } from "../query/useRoleApiActions";
import JumboFormLabelTextField from "./../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import JumboCustomLoader from "./../../../../../@jumbo/components/JumboLoader/JumboCustomLoader";

const initialValues = {
  roleName: "",
  controls: [],
  isFullAccess: false,
  status: true,
};

const RoleForm = ({ user, showDialog, hideDialog, refetchRole }) => {
  const { tryAddRole, tryEditRole } = useRoleApiActions();
  const { data: controlsData, isLoading, refetch } = useFetchAllControl();
  const [isUpdated, setIsUpdated] = useState(false);
  console.log("usr--->", user);

  const onSubmit = async (values, actions) => {
    const finalValues = {
      ...values,
      status: values.status ? "active" : "disabled",
    };

    try {
      if (user) {
        await tryEditRole({ ...finalValues, roleId: user.id }); // Assuming tryEditRole requires an ID
      } else {
        await tryAddRole(finalValues);
      }
      // await tryAddRole(finalValues);
      actions.resetForm();
      hideDialog();
      setTimeout(async () => {
        await refetchRole();
        setIsUpdated((prev) => !prev);
      }, 1000);
    } catch (error) {
      console.error("Error adding role", error);
    }
  };

  useEffect(() => {
    if (user) {
      const status = user.status ? "active" : "disabled";
      setFieldValue("roleName", user?.name || "");
      setFieldValue("isFullAccess", user?.isFullAccess || "");
      setFieldValue("status", status || "");
      setFieldValue("controls", user?.controlAccess || []);
    }
  }, [user]);

  const formik = useRoleForm(onSubmit, initialValues);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    setFieldValue,
  } = formik;

  console.log("errors---->", errors);

  return (
    <React.Fragment>
      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
          mt: -1.5,
        }}
      >
        {" "}
        <Typography>
          To add role please select permissions and provide a role name to
          create
        </Typography>
      </Div>

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <JumboFormLabelTextField
          id="roleName"
          onBlur={handleBlur}
          onChange={handleChange("roleName")}
          value={values.roleName}
          name="name"
          label="Role Name"
          placeholder={"Enter role name"}
          style={{ width: "500px" }}
        />
      </Div>
      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <FormControlLabel
          value="end"
          control={
            <Switch
              color="primary"
              checked={values.isFullAccess}
              onChange={() =>
                setFieldValue("isFullAccess", !values.isFullAccess)
              }
            />
          }
          label="Grant full access"
          labelPlacement="end"
        />

        <FormControlLabel
          value="end"
          control={
            <Switch
              color="primary"
              checked={values.status} // Controlled value from formik
              onChange={() => setFieldValue("status", !values.status)} // Toggle the value on change
            />
          }
          label="Active"
          labelPlacement="end"
        />
      </Div>

      {!values.isFullAccess && (
        <>
          <Div
            sx={{
              display: "flex",

              minWidth: 0,
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Permissions
            </Typography>
          </Div>

          {isLoading && !controlsData ? (
            <JumboCustomLoader isList={true} isCustom={false} />
          ) : (
            Object.keys(controlsData).map((e) => {
              console.log(controlsData[e]);
              const allChildren = controlsData[e]?.map((children) => ({
                controlId: children?.id,
                screenId: children?.screenId,
              }));

              return (
                <>
                  <Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <Checkbox
                        checked={allChildren.every((each) =>
                          values.controls.find(
                            (f) => f.controlId === each.controlId
                          )
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const n = [];
                            allChildren.forEach((element) => {
                              if (
                                !values.controls.find(
                                  (f) => f.controlId === element.controlId
                                )
                              ) {
                                n.push(element);
                              }
                            });
                            setFieldValue("controls", [
                              ...values.controls,
                              ...n,
                            ]);
                          } else {
                            const m = allChildren.map((k) => k.controlId);
                            const fineList = values.controls.filter(
                              (e) => !m.includes(e.controlId)
                            );
                            setFieldValue("controls", fineList);
                          }
                        }}
                      />
                      <Typography fontWeight={700}>{e}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: 2,
                        pl: 1,
                        width: "100%",
                      }}
                    >
                      {controlsData[e]?.map((children) => {
                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mr: 2,
                              }}
                            >
                              <Checkbox
                                checked={
                                  values.controls.find(
                                    (f) => f.controlId === children?.id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue("controls", [
                                      ...values.controls,
                                      {
                                        screenId: children?.screenId,
                                        controlId: children?.id,
                                      },
                                    ]);
                                  } else {
                                    setFieldValue("controls", [
                                      ...values.controls.filter(
                                        (e) => e.controlId != children?.id
                                      ),
                                    ]);
                                  }
                                }}
                              />
                              <Typography>{children?.name}</Typography>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            })
          )}
        </>
      )}

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          size="large"
          disableElevation
          sx={{
            mb: 1,
            mr: 1.5,
          }}
          // disabled={isSubmitting}
          onClick={() => hideDialog()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          disableElevation
          onClick={() => handleSubmit()}
          sx={{
            mb: 1,
          }}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Div>
    </React.Fragment>
  );
};

export default RoleForm;
