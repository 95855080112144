/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchFileById } from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";

async function getFileById(id, isVersion) {
  try {
    const response = await fetchFileById(id, isVersion);

    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchFileById = (id, isVersion, enabled = true) => {
  const cacheKey = [QueryKeys.document];
  console.log("isVersion", isVersion);
  return useQuery(
    cacheKey,
    () => {
      return getFileById(id, isVersion);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchFileById };
