/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchIndustry } from "../../../../services/auth.services";

import { QueryKeys } from "../../../../utils/QueryKeys";

async function getIndustry() {
  try {
    const response = await fetchIndustry();

    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchIndustry = (enabled = true) => {
  const cacheKey = [QueryKeys.industry];
  return useQuery(
    cacheKey,
    () => {
      return getIndustry();
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchIndustry };
