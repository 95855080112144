// A style sheet
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1200,
  },
  popup: {
    width: "330px",
    overflow: "hidden",
    borderRadius: 8,
    padding: "18px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    color: "#1F2937",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "left",
    "& p": {
      marginBottom: "10px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      textAlign: "Left",
    },
  },

  htmlContainer: {
    margin: "0px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "Left",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },

  secondary: {
    marginLeft: "5px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },

  closeButton: {
    color: "#4B5563",
    width: "40px",
    height: "40px",

    "&:focus": {
      boxShadow: "none",
    },
    "&:hover": {
      color: theme.typography.h1.color,
    },
  },
  image: {
    margin: theme.spacing(0, "auto", 2),
  },

  confirmButton: {
    border: 0,
    margin: ".3125em",
    cursor: "pointer",
    color: theme.palette.common.white,
    boxSizing: "border-box",
    fontSize: "0.875rem",
    lineHeight: 1.75,
    padding: theme.spacing(0.75, 2),
    borderRadius: 4,
    fontFamily: "inherit",
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelButton: {
    border: 0,
    margin: ".3125em",
    cursor: "pointer",
    color: theme.palette.common.white,
    boxSizing: "border-box",
    fontSize: "0.875rem",
    lineHeight: 1.75,
    padding: theme.spacing(0.75, 2),
    borderRadius: 4,
    fontFamily: "inherit",
    backgroundColor: theme.palette.grey[600],

    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  footer: {
    marginTop: "1.5rem",
    fontSize: ".875rem",
    borderColor: theme.palette.divider,
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
