import React, { useState, useEffect } from "react";
import { Button, Card, Divider, Grid } from "@mui/material";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import useSectionForm from "./../../hooks/useSectionForm";
import JumboButton from "./../../../../../../@jumbo/components/JumboButton/JumboButton";
import { useSectionFormActions } from "../../../../../redux/sectionData/sectionData.slice";
import SectionFormItem from "./SectionFormItem";
import { Delete } from "@mui/icons-material";

function SectionForm({
  section,
  index,
  handleSelectChange,
  fieldList,
  onSave,
  updateIndexGroup,
  removeSection,
}) {
  const [groupTitle, setGroupTitle] = useState(section.groupTitle || "");
  const [indexes, setIndexes] = useState(section.indexes || section || []);

  useEffect(() => {
    if (section.groupTitle) {
      setGroupTitle(section.groupTitle);
    }
  }, [section.groupTitle]);

  useEffect(() => {
    if (section.indexes) {
      setIndexes(section.indexes);
    } else {
      setIndexes(section);
    }
  }, [section.indexes, section]);

  const onSubmit = async (values) => {
    const updatedGroup = {
      groupTitle: groupTitle,
      indexes: indexes.map((field) => ({
        fieldName: field?.name || field?.fieldName,
        required: field.required,
        type: field?.type,
        options: field?.options,
      })),
    };

    onSave(index, updatedGroup);
  };

  const formik = useSectionForm(onSubmit, {
    indexGroup: [
      {
        groupTitle: groupTitle,
        indexes: indexes,
      },
    ],
  });

  const { handleBlur, handleChange, handleSubmit, values, setFieldValue } =
    formik;

  const handleIndexChange = (fieldIdx, field) => (e) => {
    const updatedIndexes = indexes?.map((item, idx) =>
      idx === fieldIdx ? { ...item, [field]: e.target.value } : item
    );
    setIndexes(updatedIndexes);
    setFieldValue(`indexGroup[${index}].indexes`, updatedIndexes);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          mb: 3.5,
          padding: 4,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => removeSection(index)}>
            <Delete color="red" />
          </Button>
        </Div>
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            gap: "20px",
          }}
        >
          <JumboFormLabelTextField
            fullWidth
            labelStyle={{
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label="Group Title"
            name={`indexGroup[${index}].groupTitle`}
            placeholder="Enter group title"
            type="text"
            value={groupTitle}
            onBlur={handleBlur(`indexGroup[${index}].groupTitle`)}
            onChange={(e) => {
              setGroupTitle(e.target.value);
              setFieldValue(`indexGroup[${index}].groupTitle`, e.target.value);
            }}
          />
          <JumboFormLabelTextField
            fullWidth
            isSelect
            menuItems={fieldList}
            labelStyle={{
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label="Field Type"
            name={`indexGroup[${index}].indexes[${index}].type`}
            placeholder=""
            type="text"
            value={values.indexGroup?.[0]?.indexes?.[index]?.type || ""}
            onBlur={handleBlur(`indexGroup[${index}].indexes[${index}].type`)}
            onSelectHandlerClick={handleSelectChange(index)}
          />
        </Div>

        <Divider sx={{ mt: 2 }} />
        <Grid container spacing={3.75} sx={{ width: "100%" }}>
          {/* <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            gap: "64px",
            mt: 2,
          }}
        > */}
          {indexes?.map((field, fieldIdx) => (
            // <Div key={fieldIdx} sx={{ flex: 1 }}>
            <Grid item xs={12} md={6} lg={6} key={fieldIdx}>
              <SectionFormItem
                field={field}
                fieldIdx={fieldIdx}
                onFieldChange={handleIndexChange(fieldIdx, "fieldName")}
              />
            </Grid>
          ))}
        </Grid>

        <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <JumboButton
            disableElevation
            variant={"contained"}
            sx={{
              border: "1px solid #371B65",
              borderRadius: "12px",
              backgroundColor: " #371B65",
              fontSize: { lg: " 16px", md: "15px", xs: "12px" },
              fontWeight: 400,
              lineHeight: "31px",
              textAlign: "left",
              textTransform: "none",
              "& .MuiSvgIcon-root": {
                fontSize: "1.5rem",
              },
            }}
            onClick={() => handleSubmit()}
          >
            Update Section
          </JumboButton>
        </Div>
      </Card>
    </>
  );
}

export default SectionForm;

// SectionForm.js
// import React, { useState, useEffect } from "react";
// import { Button, Card, Divider, Grid } from "@mui/material";
// import Div from "./../../../../../../@jumbo/shared/Div/Div";
// import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
// import useSectionForm from "./../../hooks/useSectionForm";
// import JumboButton from "./../../../../../../@jumbo/components/JumboButton/JumboButton";
// import { selectAllFieldLabels } from "../../../../../redux/sectionForm/sectionForm.slice";
// import SectionFormItem from "./SectionFormItem";
// import { Delete } from "@mui/icons-material";
// import { useSelector } from "react-redux";
// import * as Yup from "yup";
// import { useFormik } from "formik";

// function SectionForm({
//   section,
//   index,
//   handleSelectChange,
//   fieldList,
//   onSave,
//   updateIndexGroup,
//   removeSection,
// }) {
//   const [groupTitle, setGroupTitle] = useState(section.groupTitle || "");
//   const [indexes, setIndexes] = useState(section.indexes || section || []);

//   // Get all field labels from Redux store
//   const allFieldLabels = useSelector(selectAllFieldLabels);

//   useEffect(() => {
//     if (section.groupTitle) {
//       setGroupTitle(section.groupTitle);
//     }
//   }, [section.groupTitle]);

//   useEffect(() => {
//     if (section.indexes) {
//       setIndexes(section.indexes);
//     } else {
//       setIndexes(section);
//     }
//   }, [section.indexes, section]);

//   // Validation schema using Yup
//   const validationSchema = Yup.object().shape({
//     indexGroup: Yup.array().of(
//       Yup.object().shape({
//         groupTitle: Yup.string().required("Group title is required"),
//         indexes: Yup.array().of(
//           Yup.object().shape({
//             fieldName: Yup.string()
//               .required("Field name is required")
//               .test("unique", "Field name must be unique", function (value) {
//                 // Get all field names except the current one
//                 const currentSectionFieldNames = indexes
//                   .map((f) => f.fieldName)
//                   .filter((name) => name !== value);
//                 // Check against all other field labels
//                 const otherFieldLabels = allFieldLabels.filter(
//                   (name) => !currentSectionFieldNames.includes(name)
//                 );
//                 return !otherFieldLabels.includes(value);
//               }),
//             // Add other field validations if necessary
//           })
//         ),
//       })
//     ),
//   });

//   const formik = useFormik({
//     initialValues: {
//       indexGroup: [
//         {
//           groupTitle: groupTitle,
//           indexes: indexes,
//         },
//       ],
//     },
//     validationSchema,
//     onSubmit: (values) => {
//       const updatedGroup = {
//         groupTitle: groupTitle,
//         indexes: indexes.map((field) => ({
//           fieldName: field?.name || field?.fieldName,
//           required: field.required,
//           type: field?.type,
//           options: field?.options,
//         })),
//       };
//       onSave(index, updatedGroup);
//     },
//   });

//   const {
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     values,
//     setFieldValue,
//     errors,
//     touched,
//   } = formik;

//   const handleIndexChange = (fieldIdx, field) => (e) => {
//     const updatedIndexes = indexes?.map((item, idx) =>
//       idx === fieldIdx ? { ...item, [field]: e.target.value } : item
//     );
//     setIndexes(updatedIndexes);
//     setFieldValue(`indexGroup[${index}].indexes`, updatedIndexes);
//   };

//   return (
//     <Card
//       sx={{
//         display: "flex",
//         mb: 3.5,
//         padding: 4,
//         flexDirection: "column",
//         justifyContent: "center",
//       }}
//     >
//       <Div
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           justifyContent: "end",
//         }}
//       >
//         <Button onClick={() => removeSection(index)}>
//           <Delete color="red" />
//         </Button>
//       </Div>
//       <Div
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           flex: "1",
//           gap: "20px",
//         }}
//       >
//         <JumboFormLabelTextField
//           fullWidth
//           labelStyle={{
//             color: "#000000",
//             fontSize: { xs: "1.2rem", md: "16px" },
//             fontWeight: 400,
//           }}
//           label="Group Title"
//           name={`indexGroup[0].groupTitle`}
//           placeholder="Enter group title"
//           type="text"
//           value={groupTitle}
//           onBlur={handleBlur(`indexGroup[0].groupTitle`)}
//           onChange={(e) => {
//             setGroupTitle(e.target.value);
//             setFieldValue(`indexGroup[0].groupTitle`, e.target.value);
//           }}
//           error={
//             touched.indexGroup?.[0]?.groupTitle &&
//             Boolean(errors.indexGroup?.[0]?.groupTitle)
//           }
//           helperText={
//             touched.indexGroup?.[0]?.groupTitle &&
//             errors.indexGroup?.[0]?.groupTitle
//           }
//         />
//         <JumboFormLabelTextField
//           fullWidth
//           isSelect
//           menuItems={fieldList}
//           labelStyle={{
//             color: "#000000",
//             fontSize: { xs: "1.2rem", md: "16px" },
//             fontWeight: 400,
//           }}
//           label="Field Type"
//           name={`indexGroup[0].indexes[${index}].type`}
//           placeholder=""
//           type="text"
//           value={values.indexGroup?.[0]?.indexes?.[index]?.type || ""}
//           onBlur={handleBlur(`indexGroup[0].indexes[${index}].type`)}
//           onSelectHandlerClick={handleSelectChange(index)}
//           error={
//             touched.indexGroup?.[0]?.indexes?.[index]?.type &&
//             Boolean(errors.indexGroup?.[0]?.indexes?.[index]?.type)
//           }
//           helperText={
//             touched.indexGroup?.[0]?.indexes?.[index]?.type &&
//             errors.indexGroup?.[0]?.indexes?.[index]?.type
//           }
//         />
//       </Div>

//       <Divider sx={{ mt: 2 }} />
//       <Grid container spacing={3.75} sx={{ width: "100%" }}>
//         {indexes?.map((field, fieldIdx) => (
//           <Grid item xs={12} md={6} lg={6} key={fieldIdx}>
//             <SectionFormItem
//               field={field}
//               fieldIdx={fieldIdx}
//               onFieldChange={handleIndexChange(fieldIdx, "fieldName")}
//             />
//             {errors.indexGroup?.[0]?.indexes?.[fieldIdx]?.fieldName && (
//               <div style={{ color: "red" }}>
//                 {errors.indexGroup[0].indexes[fieldIdx].fieldName}
//               </div>
//             )}
//           </Grid>
//         ))}
//       </Grid>

//       <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
//         <JumboButton
//           disableElevation
//           variant={"contained"}
//           sx={{
//             border: "1px solid #371B65",
//             borderRadius: "12px",
//             backgroundColor: " #371B65",
//             fontSize: { lg: " 16px", md: "15px", xs: "12px" },
//             fontWeight: 400,
//             lineHeight: "31px",
//             textAlign: "left",
//             textTransform: "none",
//             "& .MuiSvgIcon-root": {
//               fontSize: "1.5rem",
//             },
//           }}
//           onClick={handleSubmit}
//         >
//           Update Section
//         </JumboButton>
//       </Div>
//     </Card>
//   );
// }

// export default SectionForm;
