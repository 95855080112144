import React from "react";
import { Button, IconButton } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ASSET_LOGOS } from "./../../../../../utils/constants/paths";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ConfirmPasswordPopup from "../ConfirmPasswordPopup/ConfirmPasswordPopup";
import useForgetPasswordForm from "../../hooks/useForgetPassword";
import { useAuthApiActions } from "app/pages/auth-pages/signup2/query/useAuthApiActions";
import { useNavigate } from "react-router-dom";
import useSwalWrapper from "./../../../../../../@jumbo/vendors/sweetalert2/hooks";

const defaultValues = {
  email: "",
};

const ForgetPasswordForm = ({ hideDialog }) => {
  const navigate = useNavigate();

  const origin = `${window.location.origin}`;

  const { tryForgetPassword } = useAuthApiActions();

  const Swal = useSwalWrapper();
  const sweetAlerts = (email) => {
    const handleNavigation = () => {
      Swal.close();
      // navigate(`/user/workspace/${userId}/${code}`);
      Swal.getPopup().removeEventListener("click", handleNavigation);
    };

    // const email = storeData?.email;

    Swal.fire({
      title: `Email instruction sent<p>Please follow the instructions we sent to your inbox.</p><p>${email}</p>`,
      // html: `<p>Please follow the instructions we sent to your inbox.</p><p>${email}</p>`,
      imageUrl: `${ASSET_LOGOS}/email.png`,
      imageWidth: 100,
      imageHeight: 100,
      showCloseButton: true,
      imageAlt: "Custom image",
      focusConfirm: false,
      showConfirmButton: false,
    }).then(() => {
      // navigate(`/user/workspace/${userId}/${code}`);
    });

    Swal.getPopup().addEventListener("click", handleNavigation);
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    const response = await tryForgetPassword({ ...values, origin });
    console.log("responseDara", response);

    hideDialog();
    sweetAlerts(values?.email);
  };

  const formik = useForgetPasswordForm(onSubmit, defaultValues);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    isSubmitting,
  } = formik;
  return (
    <React.Fragment>
      <Div
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "24px",
          position: "relative",
        }}
      >
        <IconButton
          onClick={hideDialog}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1,
          }}
        >
          <Close />
        </IconButton>
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={`${ASSET_LOGOS}/lock 1.png`}
            alt="Jumbo React"
            style={{ width: "100px", height: "100px" }}
          />
        </Div>
        <Div sx={{ width: "351px", mt: "36px" }}>
          <Div>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "28px",
                textAlign: "left",
                color: "#1F2937",
              }}
            >
              Forgot password?
            </Typography>
            <Typography
              sx={{
                mt: "11px",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "#6B7280",
              }}
            >
              Enter your email address to reset your password. You may need to
              check your spam folder or unblock no-reply@documan.com
            </Typography>
          </Div>
          <Div sx={{ mt: "26px", mb: "26px" }}>
            <TextField
              fullWidth
              error={!!touched.email && !!errors.email}
              helperText={(touched.email && errors && errors.email) || ""}
              name="email"
              label="Email"
              value={values.email}
              onBlur={handleBlur("email")}
              onChange={handleChange("email")}
            />
          </Div>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
            sx={{
              backgroundColor: "#371B65",
              width: "351px",
              height: "50px",
              padding: "8.59px 17.17px 8.59px 17.17px",
              gap: "8.59px",
              borderRadius: "4.29px 0px 0px 0px",
              opacity: " 0px",
              fontFamily: "Roboto",
              fontSize: "17px",
              fontWeight: 400,
              lineHeight: "25px",
              textAlign: "left",
              textTransform: "none",
            }}
          >
            Send reset instruction
          </Button>
        </Div>
      </Div>
    </React.Fragment>
  );
};

export default ForgetPasswordForm;
