import React from "react";
import Div from "@jumbo/shared/Div";
import { Button, Stack } from "@mui/material";
import { getAssetPath } from "./../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import JumboButton from "@jumbo/components/JumboButton";

function OnSelectDocument() {
  return (
    <Div
      sx={{
        display: "flex",
        width: "100%",
        height: "64px",
        gap: "15px",
        borderRadius: "12px",
        backgroundColor: "#F4EFFE",
      }}
    >
      <Stack
        sx={{
          width: "780px",
          justifyContent: "space-between",
          padding: "17px 35px 17px 35px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <JumboButton
          variant="text"
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "31px",
            textTransform: "none",
            color: "#000000",
          }}
          startIcon={
            <img
              src={getAssetPath(`${ASSET_IMAGES}/deleteButton.png`, "24x24")}
              alt="pin"
              width={24}
            />
          }
        >
          Delete
        </JumboButton>
        <JumboButton
          variant="text"
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "31px",
            textTransform: "none",
            color: "#000000",
          }}
          startIcon={
            <img
              src={getAssetPath(`${ASSET_IMAGES}/favoriteButton.png`, "24x24")}
              alt="pin"
              width={24}
            />
          }
          //   startIcon={<JumboBookmark sx={{ verticalAlign: "middle" }} />}
        >
          Favourite
        </JumboButton>
        <JumboButton
          variant="text"
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "31px",
            textTransform: "none",
            color: "#000000",
          }}
          startIcon={
            <img
              src={getAssetPath(`${ASSET_IMAGES}/downloadButton.png`, "24x24")}
              alt="pin"
              width={24}
            />
          }
        >
          Download
        </JumboButton>
        <JumboButton
          variant="text"
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "31px",
            textTransform: "none",
            color: "#000000",
          }}
          startIcon={
            <img
              src={getAssetPath(`${ASSET_IMAGES}/moveButton.png`, "24x24")}
              alt="pin"
              width={24}
            />
          }
        >
          Move to
        </JumboButton>
        <JumboButton
          variant="text"
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "31px",
            textTransform: "none",
            color: "#000000",
          }}
          startIcon={
            <img
              src={getAssetPath(`${ASSET_IMAGES}/copyButton.png`, "24x24")}
              alt="pin"
              width={24}
            />
          }
        >
          Copy to
        </JumboButton>
      </Stack>
    </Div>
  );
}

export default OnSelectDocument;
