import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  ButtonGroup,
  Box,
  Link,
} from "@mui/material";
import { getAssetPath } from "./../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../utils/constants/paths";
import UserOrders from "./UserOrders/UserOrders";
import OnSelectDocument from "./OnSelectDocument/OnSelectDocument";
import FileButton from "./FileButton/FileButton";
import Div from "@jumbo/shared/Div";
import JumboButton from "@jumbo/components/JumboButton";
import MyFiles from "./../../pages/dashboards/my-files/MyFiles";
import { useNavigate, useParams } from "react-router-dom";
import UploadPopup from "./UploadPopup";
import { useFetchAllPinnedFiles } from "app/pages/dashboards/misc/query/useFetchAllPinnedFiles";
import { PropTypes } from "prop-types";
import { useFetchFileById } from "app/pages/dashboards/file-detail/query/useFetchFileById";

const DashboardLayout = ({
  children,
  selectedUsers,
  isFolderView,
  isAllFiles,
  isRecycle,
  isShareFiles,
  handleQuery,
  isFileDetail,
  isPinned,
  isSelectPin,
  handlePinned,
  handleType,
  handleFileType,
  showPinned,
  type,
  isGridView,
}) => {
  const wordIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "41x41");
  const XcelIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/xcelicon.png`, "41x41");
  const pptIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/ppticon.png`, "41x41");
  const pdfIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/pdfIcon.png`, "41x41");

  const navigate = useNavigate();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const { location } = window;
  const fileName = location.pathname?.split("/")?.[2]
    ? location.pathname?.split("/")?.[2]
    : "";

  const dynamicFileName = fileName === "misc" ? " All Files" : fileName;

  const { data, refetch } = useFetchAllPinnedFiles(page, limit, query, false);

  useEffect(() => {
    const fetchPinnedFiles = async () => {
      // if (isPinned || isSelectPin) {
      await refetch();
      handlePinned();
      // }
    };

    fetchPinnedFiles();
  }, [isPinned, isSelectPin, refetch]);

  const handleGoToNewContainer = () => {
    navigate("/dashboards/new-container");
  };

  const handleGoToFile = (id) => {
    navigate(`/dashboards/file-details/${id}`);
  };
  return (
    <>
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        <>
          {data?.items?.length ? (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: { md: "16px", lg: "20px" },
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#000000",
                  }}
                >
                  Pinned Files
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ mt: "10px", paddingLeft: { xs: "10px" } }}
              >
                {Array.isArray(data?.items) && data?.items?.length > 0 ? (
                  data?.items?.map((data, index) => (
                    <Grid item xs={6} lg={3} md={3} key={index}>
                      <UserOrders
                        data={data}
                        handlePinned={handlePinned}
                        refetch={refetch}
                        handleGoToFile={handleGoToFile}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ display: "flex", ml: "200px", mt: "-4px" }}>
                    <Typography>No Pinned yet available</Typography>
                  </Box>
                )}
              </Grid>
            </>
          ) : null}

          {isAllFiles && (
            <>
              <Grid item xs={6} lg={4} md={4}>
                <JumboButton
                  fullWidth
                  sx={{
                    height: "58px",
                    // padding: "15px 114.55px 12px 24px",
                    border: "1px solid #371B65",
                    borderRadius: "12px",
                    backgroundColor: " #371B65",
                    fontFamily: "Roboto",
                    fontSize: { lg: " 20px", md: "15px", xs: "10px" },
                    fontWeight: 400,
                    lineHeight: "31px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  variant={"contained"}
                  startIcon={
                    <img
                      src={getAssetPath(
                        `${ASSET_IMAGES}/addButton.png`,
                        "24x24"
                      )}
                      alt="pin"
                      width={24}
                    />
                  }
                  onClick={handleGoToNewContainer}
                >
                  Add New Folder
                </JumboButton>
              </Grid>
              <Grid item xs={6} lg={4} md={4}>
                <JumboButton
                  fullWidth
                  sx={{
                    height: "58px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    borderRadius: "12px",
                    fontFamily: "Roboto",
                    fontSize: { lg: " 20px", md: "15px", xs: "10px" },
                    fontWeight: 400,
                    lineHeight: "31px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  variant={"outlined"}
                  onClick={() => setIsUploadOpen(true)}
                  startIcon={
                    <img
                      src={getAssetPath(
                        `${ASSET_IMAGES}/uploadButton.png`,
                        "24x24"
                      )}
                      alt="pin"
                      width={24}
                    />
                  }
                >
                  Upload
                </JumboButton>
              </Grid>
              <UploadPopup
                open={isUploadOpen}
                handleClose={() => setIsUploadOpen(false)}
                // selectedValue={selectedDocumentIds}
              />
              {/* <Grid item xs={12} lg={4} md={4}>
                <JumboButton
                  fullWidth
                  sx={{
                    height: "58px",
                    // padding: "15px 80px 12px 24px",
                    border: "1px solid #371B65",
                    borderRadius: "12px",
                    color: "#371B65",
                    fontFamily: "Roboto",
                    fontSize: { lg: " 20px", md: "15px", xs: "10px" },
                    fontWeight: 400,
                    lineHeight: "31px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  variant={"outlined"}
                  startIcon={
                    <img
                      src={getAssetPath(
                        `${ASSET_IMAGES}/folderButton.png`,
                        "24x24"
                      )}
                      alt="pin"
                      width={24}
                    />
                  }
                >
                  Add Shared Folder
                </JumboButton>
              </Grid> */}
            </>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: { md: "16px", lg: "20px" },
                fontWeight: "400",
                lineHeight: "20px",
                color: "#000000",
              }}
            >
              {isFileDetail ? <FileDetailBreadcrumb /> : dynamicFileName}
            </Typography>
          </Grid>
          {!isFileDetail && (
            <Grid item xs={12} md={12} lg={12}>
              {selectedUsers.length > 0 ? (
                <OnSelectDocument />
              ) : (
                <FileButton
                  isFolderView
                  isAllFiles={isAllFiles}
                  isShareFiles={isShareFiles}
                  handleQuery={handleQuery}
                  handleType={handleType}
                  handleFileType={handleFileType}
                  type={type}
                  isGridView={isGridView}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            {children}
          </Grid>
        </>
        {/* )} */}
      </Grid>
    </>
  );
};

const FileDetailBreadcrumb = () => {
  const { id } = useParams();

  const { data: fileData } = useFetchFileById(id, false, Boolean(id.length));

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/">
        Files
      </Link>
      <Typography color="text.primary">{fileData?.fileName || ""}</Typography>
    </Breadcrumbs>
  );
};

DashboardLayout.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  isFolderView: PropTypes.bool,
  isAllFiles: PropTypes.bool,
  isRecycle: PropTypes.bool,
  isShareFiles: PropTypes.bool,
  handleQuery: PropTypes.func,
  isFileDetail: PropTypes.bool,
  isPinned: PropTypes.bool,
  isSelectPin: PropTypes.bool,
  handlePinned: PropTypes.func,
  handleType: PropTypes.func,
  handleFileType: PropTypes.func,
  type: PropTypes.string,
  isGridView: PropTypes.bool,
  // Specify that handlePinned is a function, but it's optional
};

DashboardLayout.defaultProps = {
  isRecycle: false,
  isPinned: false,
  isSelectPin: false,
  handlePinned: () => {},
  handleType: () => {},
  handleFileType: () => {},
  handleQuery: () => {},
  isGridView: false,
};
export default DashboardLayout;
