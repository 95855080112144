import React, { useState } from "react";
import DashboardLayout from "./../../../layouts/dashboard-layout/DashboardLayout";
import FilesList from "./components/FilesList/FilesList";
import FolderList from "./components/FolderList/FolderList";

function MiscDashboard() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [isSelectPin, setIsSelectPin] = useState(false);
  const [type, setType] = useState("file");

  const handleType = () => {
    setType("folder");
  };

  const handleFileType = () => {
    setType("file");
  };
  const handleDocumentSelection = (selected) => {
    setSelectedUsers(selected);
  };
  const handlePinned = () => {
    setIsSelectPin(true);
  };

  const handleReverse = () => {
    setIsSelectPin(false);
  };

  const handleQuery = (newQuery) => {
    setQuery(newQuery);
  };

  console.log("query-->", query);
  return (
    <DashboardLayout
      selectedUsers={selectedUsers}
      isFolderView={false}
      isAllFiles={true}
      handleQuery={handleQuery}
      isPinned={true}
      isSelectPin={isSelectPin}
      handlePinned={handleReverse}
      handleType={handleType}
      handleFileType={handleFileType}
      type={type}
    >
      {type === "file" ? (
        <FilesList
          selectedUsers={selectedUsers}
          onDocumentSelect={handleDocumentSelection}
          query={query}
          handlePinned={handlePinned}
        />
      ) : (
        <FolderList
          selectedUsers={selectedUsers}
          onDocumentSelect={handleDocumentSelection}
          query={query}
          handlePinned={handlePinned}
        />
      )}
    </DashboardLayout>
  );
}

export default MiscDashboard;
