import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  ASSET_IMAGES,
  ASSET_LOGOS,
} from "./../../../../../utils/constants/paths";
import OutlinedSecondaryTextField from "./../../../../../../@jumbo/components/Textfield/index";
import AuthLayout from "app/layouts/auth-layout/AuthLayout";
import useConfirmPasswordForm from "../../hooks/useConfirmPassword";
import { useAuthApiActions } from "app/pages/auth-pages/signup2/query/useAuthApiActions";

const defaultValues = {
  password: "",
  confirmPassword: "",
};

const ConfirmPasswordPopup = ({ hideDialog }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { tryResetPassword } = useAuthApiActions();

  const { location } = window;
  const email = location.pathname?.split("/")?.[3]
    ? location.pathname?.split("/")?.[3]
    : "";

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    const password = values.password;
    const response = await tryResetPassword({ password, email });
    // if (response) {
    //   hideDialog();
    // }
  };

  const formik = useConfirmPasswordForm(onSubmit, defaultValues);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    isSubmitting,
  } = formik;

  const passwordMatch =
    values.password === values.confirmPassword && values.password !== "";

  return (
    <AuthLayout
      title={" Create a strong password"}
      description={
        " Create a strong password with a mixture of letters, numbers and symbols."
      }
    >
      <Div
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "24px",
          position: "relative",
        }}
      >
        {/* <IconButton
          onClick={hideDialog}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1,
          }}
        >
          <Close />
        </IconButton> */}
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={`${ASSET_LOGOS}/key-chain.png`}
            alt="Jumbo React"
            style={{ width: "100px", height: "100px" }}
          />
        </Div>
        <Div sx={{ width: "351px", mt: "36px" }}>
          <Div>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "28px",
                textAlign: "left",
                color: "#1F2937",
              }}
            >
              Reset Your Password
            </Typography>
          </Div>
          <Div sx={{ mt: "24px", mb: "24px" }}>
            <Div sx={{ mt: "20px", width: "349px" }}>
              <OutlinedSecondaryTextField
                focused
                error={!!touched.password && !!errors.password}
                helperText={
                  (touched.password && errors && errors.password) || ""
                }
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                placeholder={"eg. *******"}
                endAdornment={
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? (
                      <img
                        src={`${ASSET_IMAGES}/openeye.png`}
                        alt="Jumbo React"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ) : (
                      <img
                        src={`${ASSET_IMAGES}/closeeye.png`}
                        alt="Jumbo React"
                        style={{ width: "24px", height: "24px" }}
                      />
                    )}
                  </IconButton>
                }
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                value={values.password}
                onBlur={handleBlur("password")}
                onChange={handleChange("password")}
              />
            </Div>
            <Div sx={{ mt: "20px", width: "349px" }}>
              <OutlinedSecondaryTextField
                focused
                error={
                  (!!touched.confirmPassword && !!errors.confirmPassword) ||
                  (!passwordMatch && touched.confirmPassword)
                }
                helperText={
                  (touched.confirmPassword &&
                    errors &&
                    errors.confirmPassword) ||
                  (!passwordMatch &&
                    touched.confirmPassword &&
                    "Passwords do not match")
                }
                label="Confirm Password"
                variant="outlined"
                placeholder={"eg. *******"}
                endAdornment={
                  <IconButton onClick={handleToggleConfirmPassword} edge="end">
                    {showPassword ? (
                      <img
                        src={`${ASSET_IMAGES}/openeye.png`}
                        alt="Jumbo React"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ) : (
                      <img
                        src={`${ASSET_IMAGES}/closeeye.png`}
                        alt="Jumbo React"
                        style={{ width: "24px", height: "24px" }}
                      />
                    )}
                  </IconButton>
                }
                InputProps={{
                  style: { color: "#9B9B9B" },
                }}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                value={values.confirmPassword}
                onBlur={handleBlur("confirmPassword")}
                onChange={handleChange("confirmPassword")}
              />
            </Div>
          </Div>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
            sx={{
              backgroundColor: "#371B65",
              width: "351px",
              height: "50px",
              padding: "8.59px 17.17px 8.59px 17.17px",
              gap: "8.59px",
              borderRadius: "4.29px 0px 0px 0px",
              opacity: " 0px",
              fontFamily: "Roboto",
              fontSize: "17px",
              fontWeight: 400,
              lineHeight: "25px",
              textAlign: "left",
              textTransform: "none",
            }}
          >
            Reset Password
          </Button>
        </Div>
      </Div>
    </AuthLayout>
  );
};

export default ConfirmPasswordPopup;
