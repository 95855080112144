import { getAssetPath } from "./../../../../utils/appHelpers";
import { ASSET_AVATARS } from "./../../../../utils/constants/paths";

export const menuItems = [
  {
    icon: null,
    title: "View",
    slug: "all",
  },
  {
    icon: null,
    title: "Edit",
    slug: "edit",
  },
  {
    icon: null,
    title: "Delete",
    slug: "delete",
  },
];

export const users = [
  {
    id: "U_123",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "56x56"),
    fullName: "Jannie Thompson",

    mobile: "925867546",

    email: "jannie@gmail.com",
    password: "12346",
    activity: "Salman Syed edited this | Apr 17 ",
    dateShared: "Mar 15, 2023",
    sharedBy: "Ankita",
  },
  {
    id: "U_124",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "56x56"),
    fullName: "Eliza Shelton",
    mobile: "925867546",
    email: "jannie@gmail.com",
    companyName: "Sanicon Services",
    companyEmail: "saniconService@gmail.com",
    companyMobile: "4564321",
    activity: "Salman Syed edited this | Apr 17 ",
    dateShared: "Mar 15, 2023",
    sharedBy: "Saquib Jamai",
  },
  {
    id: "U_125",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "56x56"),
    fullName: "Irene Owen",
    activity: "Salman Syed edited this | Apr 17 ",
    dateShared: "Mar 15, 2023",
    sharedBy: "Saquib Jamai",
    mobile: "925867546",
    email: "jannie@gmail.com",
    companyName: "Sanicon Services",
    companyEmail: "saniconService@gmail.com",
    companyMobile: "4564321",
    companyAddress: "XYz",
  },
  {
    id: "U_126",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "56x56"),
    fullName: "Irene Owen",
    activity: "Salman Syed edited this | Apr 17 ",
    dateShared: "Mar 15, 2023",
    sharedBy: "Nijin",
    mobile: "925867546",
    email: "jannie@gmail.com",
    companyName: "Sanicon Services",
    companyEmail: "saniconService@gmail.com",
    companyMobile: "4564321",
    companyAddress: "XYz",
  },
];
