/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchAllFolderData } from "../../../../services/pages.services";

import { QueryKeys } from "../../../../utils/QueryKeys";

async function getAllFolder(page, limit, query) {
  try {
    const response = await fetchAllFolderData(page, limit, query);
    console.log("roles data", response);
    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchAllFolderData = (page, limit, query, enabled = true) => {
  const cacheKey = [QueryKeys.folder];
  return useQuery(
    cacheKey,
    () => {
      return getAllFolder(page, limit, query);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchAllFolderData };
