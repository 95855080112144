import React from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";

import {
  Card,
  Typography,
  TableCell,
  TableRow,
  Checkbox,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { useNavigate } from "react-router-dom";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import moment from "moment";
import FileIcon from "./../../../workspace/components/FileIcon/index";
import { useDashboardApiAction } from "app/pages/dashboards/misc/query/useDashboardApiAction";
import LockIcon from "@mui/icons-material/Lock";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { QueryKeys } from "app/utils/QueryKeys";

const Item = styled(Span)(({ theme }) => ({}));

const FilesListItem = ({
  user,
  isSelected,
  handleCheckboxChange,
  handlePinned,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tryFavoriteFile, tryPinnedFile } = useDashboardApiAction();

  const fileSize = user?.size / (1024 * 1024);

  const showEditUser = () => {
    navigate(`/dashboards/users/edit-user/${user.id}`);
  };

  const handleGoToFile = () => {
    navigate(`/dashboards/file-details/${user.id}`);
  };

  const handlePinnedFile = async () => {
    console.log("Image clicked, handling pin...");
    try {
      await tryPinnedFile({ fileId: user.id });
      handlePinned();
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const handleFavorite = async () => {
    try {
      await tryFavoriteFile({ fileId: user.id });
      console.log("Document upload successful");
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
          // checked={isSelected}
          // onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "40%", md: "40%", xs: "60%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              {/* <img
                src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "32x32")}
                alt="pin"
                width={32}
              /> */}
              <FileIcon type={user?.fileType} />
            </Item>
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
                cursor: "pointer",
              }}
              onClick={
                user?.checkInCheckOutStatus !== "checkout"
                  ? handleGoToFile
                  : () => {
                      enqueueSnackbar("The file is checkout", {
                        variant: "error",
                      });
                    }
              }
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                }}
              >
                {user.fileName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                  color: "#A5A5A5",
                }}
              >
                {fileSize?.toFixed(2)} MB
              </Typography>
            </Item>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "column", lg: "row" }}
              alignItems="center"
              sx={{ mt: { md: 1, lg: 0 } }} // Add margin-top for md to create space when in column
            >
              {/* <Item sx={{ mb: "12px" }}> */}
              <JumboBookmark
                value={user.isFavourite}
                sx={{ verticalAlign: "middle" }}
                onChange={handleFavorite}
              />

              {user?.isPinned ? (
                <img
                  src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "16x16")}
                  alt="pin"
                  width={18}
                  onClick={handlePinnedFile}
                  style={{
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <PushPinOutlinedIcon
                  sx={{
                    fontSize: "18px",
                    transform: "rotate(52.5deg)",
                    ml: "7px",
                    cursor: "pointer",
                  }}
                  onClick={handlePinnedFile}
                />
              )}

              {/* <img
                src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "14x14")}
                alt="pin"
                width={14}
                onClick={handlePinnedFile}
                style={{
                  verticalAlign: "middle",
                  marginLeft: "10px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              /> */}
              {user?.checkInCheckOutStatus === "checkout" && (
                <LockIcon
                  color="primary"
                  sx={{
                    marginLeft: { lg: "20px", md: "0px", xs: "0px" },
                    mt: { lg: "-5px", md: "0px", xs: "0px" },
                  }}
                />
              )}

              {/* <img
                src={getAssetPath(`${ASSET_IMAGES}/shareIcon.png`, "14x14")}
                alt="pin"
                width={14}
                style={{ verticalAlign: "middle" }}
              /> */}
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "15%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {moment(user?.updatedAt).format("MMMM DD , h:mm A")}
          </Typography>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "18%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.modifiedBy}
          </Typography>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "12%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.version}
          </Typography>
        </TableCell>
        {/* <TableCell align="Left" style={{ width: "10%" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.sharing}
          </Typography>
        </TableCell> */}
        <TableCell
          align="left"
          sx={{ width: { lg: "15%", md: "40%", xs: "40%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/editIcon.png`, "24x24")}
                alt="pin"
                width={24}
              />
            </Item>
            <Item>
              {" "}
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: " 0.025em",
                  color: "#9CA3AF",
                }}
              >
                created this|{moment(user?.createdAt).format("MMMM DD, h:mm A")}
                {/* edited this| {moment(user?.updatedAt).format("MMMM DD , h:mm A")} */}
              </Typography>
            </Item>
          </Stack>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
