import React, { useState } from "react";
import useJumboApp from "@jumbo/hooks/useJumboApp";
import { config } from "../../../app/config/main";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { useAccessControlQuery } from "./query/useAccessControlQuery";
import { useAccessActions } from "app/redux/access/access.slice";
import { useAppSelector } from "app/redux/store";

const Page = ({ component, layout, ...restProps }) => {
  const { activeLayout, setActiveLayout } = useJumboApp();

  const [loading, setLoading] = useState(false);

  const { code } = restProps;
  const { setAccessData } = useAccessActions();
  // const accessData = useAppSelector((store) => store.accessData);

  const { data } = useAccessControlQuery(code);
  // console.log(accessData);
  if (data) {
    setAccessData({
      [code]: data,
    });
  }

  React.useEffect(() => {
    if (!code) return;
  }, [code]);

  React.useEffect(() => {
    if (layout !== activeLayout) {
      setActiveLayout(layout);
    }
  }, [layout]);

  const PageComponent = component;

  return loading ? (
    <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
      }}
    >
      <CircularProgress sx={{ m: "-40px auto 0" }} />
    </Div>
  ) : (
    <PageComponent {...restProps} />
  );
};

Page.defaultProps = {
  layout: config.defaultLayout,
};

export default Page;
