import React from "react";
import Stack from "@mui/material/Stack";
import { Card, Typography } from "@mui/material";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { menuItems } from "../../data";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import { useJumboDialog } from "./../../../../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useUserApiAction } from "../../query/useUserApiActions";
import useDecodedData from "app/hooks/useDecodedData";
import { useSnackbar } from "notistack";

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

const UserItem = ({ user }) => {
  const navigate = useNavigate();
  const decode = useDecodedData();
  console.log("decoded data --->", decode);
  console.log("usser dat a---->0", user);
  const { showDialog, hideDialog } = useJumboDialog();
  const { tryDeleteUser } = useUserApiAction();
  const { enqueueSnackbar } = useSnackbar();

  const showEditUser = () => {
    navigate(`/dashboards/users/edit-user/${user.id}`);
  };

  const handleDeleteUser = (userId) => {
    if (decode.userId === userId) {
      enqueueSnackbar("User cannot delete themself", {
        variant: "error",
      });
    } else {
      showDialog({
        variant: "confirm",
        title: "Are you sure about deleting this contact?",
        content: "You won't be able to recover this contact later",
        onYes: () => {
          tryDeleteUser?.(userId);

          hideDialog();
        },
        onNo: hideDialog,
      });
    }
  };

  return (
    <Card sx={{ mb: 1 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ p: (theme) => theme.spacing(2, 1) }}
      >
        <Item
          sx={{
            flex: { xs: 1, md: "0 1 45%", lg: "0 1 35%" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item sx={{ ml: -1 }}>
              <JumboBookmark
                value={user?.isFavorite}
                sx={{ verticalAlign: "middle" }}
              />
            </Item>
            {/* <Item>
              <Badge
                overlap="circular"
                variant="dot"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  ".MuiBadge-badge": {
                    border: "2px solid #FFF",
                    height: "14px",
                    width: "14px",
                    borderRadius: "50%",
                    bgcolor: user.isOnline ? "success.main" : "#757575",
                  },
                }}
              >
                <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                  }}
                  alt={`${user.firstName} 
                                    ${user.lastName}`}
                  src={user.profilePic}
                />
              </Badge>
            </Item> */}
            <Item>
              <Typography
                variant={"h6"}
                mb={0.5}
              >{`${user?.firstName} ${user?.lastName}`}</Typography>
            </Item>
          </Stack>
        </Item>
        <Item
          sx={{
            flexBasis: { md: "28%", lg: "30%" },
            display: { xs: "none", md: "none", lg: "block", xl: "block" },
          }}
        >
          <Typography variant={"h6"} mt={1} lineHeight={1.25}>
            {user?.email}
          </Typography>
        </Item>
        <Item
          sx={{
            flexBasis: "15%",
            display: { xs: "none", lg: "block" },
          }}
        >
          <Item>
            <Typography variant={"h6"} mb={0.5}>
              {user?.mobile}
            </Typography>
          </Item>
        </Item>
        <Item
          sx={{
            flexBasis: "30%",
            display: { xs: "none", sm: "block" },
          }}
        >
          <Typography variant={"h6"} mb={0.5}>
            {/* {user.createdAt} */}
            {moment(user?.createdAt).format("DD/MM/YYYY h:mm A")}
          </Typography>
        </Item>
        <Item sx={{ ml: { xs: "auto", sm: 0 } }}>
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={(action) => {
              if (action === "edit") {
                showEditUser();
              } else if (action === "delete") {
                handleDeleteUser(user?.id);
              }
            }}
          />
        </Item>
      </Stack>
    </Card>
  );
};

export default UserItem;
