import React from "react";
import { Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
const PageHeader = ({
  title,
  subheader,
  showAddContactDialog,
  isAddButton,
  buttonText,
}) => {
  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 4,
      }}
    >
      <Div
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        {" "}
        <Typography variant={"h2"}>{title}</Typography>
        {isAddButton && (
          <Button
            disableElevation
            variant={"contained"}
            startIcon={<PersonAddIcon />}
            sx={{
              padding: "10px 16px 10px 16px",
              gap: "8px",
              backgroundColor: "#371B65",
              textTransform: "none",
              fontFamily: "Roboto",
              fontSize: { lg: "14px", md: "12px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.05em",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "primary.main",
              },
            }}
            onClick={showAddContactDialog}
          >
            {buttonText || "Add Contact"}
          </Button>
        )}
      </Div>

      <Typography variant={"body1"} mb={2} color={"text.secondary"}>
        {subheader}
      </Typography>
    </Div>
  );
};

export default PageHeader;
