const layoutConfig = {
  sidebar: {
    hide: true,
  },
  content: {
    sx: {
      p: 0,

      px: { lg: 0, xs: "20px", md: "15px" },
      mt: "50px",
      ml: { lg: "-120px", md: 0, xs: 0 },
    },
  },

  header: {
    hide: false,
    fixed: true,
  },
  footer: {
    hide: true,
  },
};

export default layoutConfig;
