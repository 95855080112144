import React from "react";
import { Grid, Button, ButtonGroup } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { getAssetPath } from "./../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import DocumentSortCard from "./../DocumentSortCard/index";
import SearchGlobal from "./../../../shared/SearchGlobal/SearchGlobal";
import { useNavigate } from "react-router-dom";
import JumboButton from "@jumbo/components/JumboButton";

function FileButton({
  isFolderView,
  isAllFiles,
  isShareFiles,
  handleQuery,
  handleType,
  handleFileType,
  type,
  isGridView,
}) {
  const navigate = useNavigate();

  const handleGoToFolderGridView = () => {
    navigate("/dashboards/misc/grid-view-folder");
  };

  const handleGoToGridView = () => {
    navigate("/dashboards/misc/grid-view");
  };

  const handleGoToFolderListView = () => {
    navigate("/dashboards/misc");
  };

  const handleGoToListView = () => {
    navigate("/dashboards/misc");
  };
  return (
    <Grid container>
      {isAllFiles && (
        <Grid item xs={12} sm={12} lg={12}>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
            }}
          >
            <Div>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  startIcon={
                    <img
                      src={getAssetPath(`${ASSET_IMAGES}/files.png`, "18x18")}
                      alt="pin"
                      width={18}
                    />
                  }
                  sx={{
                    width: "91px",
                    height: "32px",
                    padding: "6px 16px 6px 16px",
                    gap: "8px",
                    backgroundColor: "#371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                  }}
                  onClick={handleFileType}
                >
                  Files
                </Button>
                <Button
                  variant="outlined"
                  startIcon={
                    <img
                      src={getAssetPath(`${ASSET_IMAGES}/folder.png`, "18x18")}
                      alt="pin"
                      width={18}
                    />
                  }
                  sx={{
                    width: "101px",
                    height: "32px",
                    padding: "6px, 16px, 6px, 16px",
                    gap: "8px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                  }}
                  onClick={handleType}
                >
                  Folder
                </Button>
              </ButtonGroup>
            </Div>

            <Div sx={{ mt: { xs: "10px" } }}>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <JumboButton
                  variant="outlined"
                  onClick={
                    type === "file"
                      ? handleGoToGridView
                      : handleGoToFolderGridView
                  }
                  sx={{
                    height: "32px",
                    padding: "6px, 16px, 6px, 16px",
                    gap: "8px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                    textAlign: "left",
                    cursor: isFolderView ? "pointer" : "not-allowed",
                  }}
                >
                  Grid View
                </JumboButton>
                <JumboButton
                  sx={{
                    height: "32px",
                    padding: "6px 16px 6px 16px",
                    gap: "8px",
                    backgroundColor: "#371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                    textAlign: "left",
                  }}
                  onClick={
                    type === "file"
                      ? handleGoToListView
                      : handleGoToFolderListView
                  }
                >
                  List View
                </JumboButton>
              </ButtonGroup>
            </Div>
          </Div>
        </Grid>
      )}

      {isGridView && (
        <Grid item xs={12} sm={12} lg={12}>
          <Div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
            }}
          >
            <Div sx={{ mt: { xs: "10px" } }}>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <JumboButton
                  variant="outlined"
                  onClick={
                    type === "file"
                      ? handleGoToGridView
                      : handleGoToFolderGridView
                  }
                  sx={{
                    height: "32px",
                    padding: "6px, 16px, 6px, 16px",
                    gap: "8px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                    textAlign: "left",
                    cursor: isFolderView ? "pointer" : "not-allowed",
                  }}
                >
                  Grid View
                </JumboButton>
                <JumboButton
                  sx={{
                    height: "32px",
                    padding: "6px 16px 6px 16px",
                    gap: "8px",
                    backgroundColor: "#371B65",
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: { lg: "14px", md: "12px", xs: "12px" },
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.05em",
                    textAlign: "left",
                  }}
                  onClick={
                    type === "file"
                      ? handleGoToListView
                      : handleGoToFolderListView
                  }
                >
                  List View
                </JumboButton>
              </ButtonGroup>
            </Div>
          </Div>
        </Grid>
      )}

      <Grid item xs={12} md={12} lg={12} sx={{ mt: "11px" }}>
        <Grid container justifyContent="flex-end">
          {/* <Grid
            item
            container
            xs={12}
            md={9}
            lg={7}
            xl={7}
            sx={{
              display: "flex",
              justifyContent: { xs: "space-between", lg: "space-between" },
              height: "60px",
              alignItems: "center",
              px: "5px",
              // gap: { lg: "24.18px" },
              borderRadius: "8px",
              border: "0.76px solid #371B6566",
            }}
          >
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/allFileIcon.png`, "17x15")}
              title="All Files"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "17x15")}
              title="Word file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/xcelicon.png`, "17x15")}
              title="Excel file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/ppticon.png`, "17x15")}
              title="Powerpoint file"
              subtitle="100 MB"
            />
            <DocumentSortCard
              Iconsrc={getAssetPath(`${ASSET_IMAGES}/pdfIcon.png`, "17x15")}
              title="PDF"
              subtitle="100 MB"
            />
          </Grid> */}
          <Grid
            xs={12}
            md={12}
            lg={4}
            item
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: { md: "11px", xs: "11px" },
            }}
          >
            {/* {isAllFiles && (
              <JumboButton
                variant="text"
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px" },
                  fontWeight: 400,
                  lineHeight: "31px",
                  textAlign: "left",
                  color: "#000000",
                }}
                startIcon={
                  <img
                    src={getAssetPath(
                      `${ASSET_IMAGES}/sortSymbol.png`,
                      "24x24"
                    )}
                    alt="pin"
                    width={18}
                  />
                }
                endIcon={
                  <img
                    src={getAssetPath(`${ASSET_IMAGES}/more.png`, "18x18")}
                    alt="pin"
                    width={18}
                  />
                }
              >
                Sort
              </JumboButton>
            )} */}
            {isShareFiles && (
              <div>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <JumboButton
                    sx={{
                      width: "85px",
                      height: "46px",
                      padding: "6px 16px",
                      gap: "8px",
                      backgroundColor: "#371B65",
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.05em",
                      textAlign: "left",
                    }}
                  >
                    With you
                  </JumboButton>
                  <JumboButton
                    variant="outlined"
                    sx={{
                      width: "85px",
                      height: "46px",
                      padding: "6px 16px",
                      gap: "8px",
                      color: "#371B65",
                      border: "1px solid #371B65",
                      textTransform: "none",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.05em",
                      textAlign: "left",
                    }}
                  >
                    By You
                  </JumboButton>
                </ButtonGroup>
              </div>
            )}
            <SearchGlobal
              sx={{
                maxWidth: { xs: 240, md: 300 },
                ml: "10px",
              }}
              onChange={handleQuery}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FileButton;
