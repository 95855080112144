import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, FormControlLabel, FormLabel, Radio } from "@mui/material";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import Span from "./../../../../../../@jumbo/shared/Span/Span";
import useSectionFormItem from "../../hooks/useSectionFormItem";
import { CheckBox } from "@mui/icons-material";

const defaultValues = {
  indexes: [
    {
      name: "",
      type: "",
      options: [],
      required: true,
    },
  ],
};

function SectionFormItem({ field, index, fieldIndex }) {
  const [formValues, setFormValues] = useState({});

  let parsedOptions = [];
  try {
    parsedOptions = JSON.parse(
      field.options.replace(/(\w+:)|(\w+ :)/g, function (matched) {
        return '"' + matched.substring(0, matched.length - 1) + '":';
      })
    );
    console.log("Parsed options:", parsedOptions);
  } catch (e) {
    console.error("Failed to parse options:", e);
  }

  // <FormControlLabel
  //   control={
  //     <Checkbox
  //       checked={formValues[field.name] || false}
  //       onChange={handleChange(`indexGroup[0].indexes[${index}].name`)}
  //     />
  //   }
  //   label={field.name}
  // />

  const menuItems = useMemo(
    () =>
      Array.isArray(parsedOptions)
        ? parsedOptions.map((option) => {
            return { id: option.value, name: option.value };
          })
        : [],
    [parsedOptions]
  );

  const onSubmit = async (values) => {
    console.log("Form submitted", values);
  };

  const formik = useSectionFormItem(onSubmit, defaultValues);
  const { handleChange } = formik;

  const fieldType = field.type.trim();
  let formItem = null;

  switch (fieldType) {
    case "DropDown":
      formItem = (
        <JumboFormLabelTextField
          fullWidth
          isSelect
          menuItems={menuItems}
          labelStyle={{
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={field.name}
          placeholder=""
          type="text"
          onChange={handleChange(
            `indexGroup[0].indexes[${index}].options[${fieldIndex}]`
          )}
        />
      );
      break;
    case "TextField":
      formItem = (
        <JumboFormLabelTextField
          fullWidth
          labelStyle={{
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={field.name}
          placeholder={`Enter ${field.name}`}
          type="text"
          onChange={handleChange(`indexGroup[0].indexes[${index}].name`)}
        />
      );
      break;
    case "Checkbox":
      formItem = (
        <Div sx={{ display: "flex", flexDirection: "column" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              zIndex: 9999,
              textTransform: "capitalize",
            }}
          >
            {field?.name}
          </FormLabel>
          <Span>
            {parsedOptions.length > 0 &&
              parsedOptions.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  value={option.value}
                  control={
                    <Checkbox
                      checked={
                        formValues[`${field.name}-${option.value}`] || false
                      }
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [`${field.name}-${option.value}`]: e.target.checked,
                        });
                        handleChange(
                          `indexGroup[0].indexes[${index}].options[${fieldIndex}]`
                        )(e);
                      }}
                    />
                  }
                  label={option.value}
                />
              ))}
          </Span>
        </Div>
      );
      break;

    case "RadioButton":
      formItem = (
        <Div sx={{ display: "flex", flexDirection: "column" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              zIndex: 9999,
              textTransform: "capitalize",
            }}
          >
            {field?.name}
          </FormLabel>
          <Span>
            {parsedOptions.length > 0 &&
              parsedOptions.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  value={option.value}
                  control={<Radio />}
                  label={option.value}
                  onChange={handleChange(
                    `indexGroup[0].indexes[${index}].options[${fieldIndex}]`
                  )}
                />
              ))}
          </Span>
        </Div>
      );
      break;
    default:
      formItem = null;
  }

  return formItem;
}

export default SectionFormItem;

// SectionFormItem.js
// import React, { useMemo } from "react";
// import {
//   Checkbox,
//   FormControlLabel,
//   FormLabel,
//   Radio,
//   FormHelperText,
// } from "@mui/material";
// import Div from "./../../../../../../@jumbo/shared/Div/Div";
// import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
// import Span from "./../../../../../../@jumbo/shared/Span/Span";
// import { useFormikContext } from "formik";

// function SectionFormItem({ field, index, fieldIdx }) {
//   let parsedOptions = [];
//   try {
//     parsedOptions = JSON.parse(
//       field.options.replace(/(\w+:)|(\w+ :)/g, function (matched) {
//         return '"' + matched.substring(0, matched.length - 1) + '":';
//       })
//     );
//     console.log("Parsed options:", parsedOptions);
//   } catch (e) {
//     console.error("Failed to parse options:", e);
//   }

//   const menuItems = useMemo(
//     () =>
//       Array.isArray(parsedOptions)
//         ? parsedOptions.map((option) => ({
//             id: option.value,
//             name: option.value,
//           }))
//         : [],
//     [parsedOptions]
//   );

//   const { errors, touched, handleChange, setFieldValue } = useFormikContext();

//   const fieldError = errors.indexGroup?.[0]?.indexes?.[index]?.fieldName;
//   const fieldTouched = touched.indexGroup?.[0]?.indexes?.[index]?.fieldName;

//   const fieldType = field.type.trim();
//   let formItem = null;

//   switch (fieldType) {
//     case "DropDown":
//       formItem = (
//         <JumboFormLabelTextField
//           fullWidth
//           isSelect
//           menuItems={menuItems}
//           labelStyle={{
//             color: "#000000",
//             fontSize: { xs: "1.2rem", md: "16px" },
//             fontWeight: 400,
//           }}
//           label={field.name}
//           placeholder=""
//           type="text"
//           name={`indexGroup[0].indexes[${index}].options[${fieldIdx}]`}
//           onChange={handleChange}
//           error={fieldTouched && Boolean(fieldError)}
//           helperText={fieldTouched && fieldError}
//         />
//       );
//       break;
//     case "TextField":
//       formItem = (
//         <JumboFormLabelTextField
//           fullWidth
//           labelStyle={{
//             color: "#000000",
//             fontSize: { xs: "1.2rem", md: "16px" },
//             fontWeight: 400,
//           }}
//           label={field.name}
//           placeholder={`Enter ${field.name}`}
//           type="text"
//           name={`indexGroup[0].indexes[${index}].fieldName`}
//           onChange={handleChange}
//           error={fieldTouched && Boolean(fieldError)}
//           helperText={fieldTouched && fieldError}
//         />
//       );
//       break;
//     case "Checkbox":
//       formItem = (
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={field.required || false}
//               onChange={(e) =>
//                 setFieldValue(
//                   `indexGroup[0].indexes[${index}].required`,
//                   e.target.checked
//                 )
//               }
//             />
//           }
//           label={field.name}
//         />
//       );
//       break;
//     case "RadioButton":
//       formItem = (
//         <Div sx={{ display: "flex", flexDirection: "column" }}>
//           <FormLabel
//             sx={{
//               color: "inherit",
//               fontSize: {
//                 xs: "12px",
//                 md: "14px",
//                 xl: "15px",
//                 fontWeight: "500",
//               },
//               marginTop: "5px",
//               marginBottom: "4px",
//               position: "relative",
//               zIndex: 9999,
//               textTransform: "capitalize",
//             }}
//           >
//             {field.name}
//           </FormLabel>
//           <Span>
//             {parsedOptions.length > 0 &&
//               parsedOptions.map((option, idx) => (
//                 <FormControlLabel
//                   key={idx}
//                   value={option.value}
//                   control={<Radio />}
//                   label={option.value}
//                   name={`indexGroup[0].indexes[${index}].options[${fieldIdx}]`}
//                   onChange={handleChange}
//                 />
//               ))}
//           </Span>
//           {fieldTouched && fieldError && (
//             <FormHelperText error>{fieldError}</FormHelperText>
//           )}
//         </Div>
//       );
//       break;
//     default:
//       formItem = null;
//   }

//   return formItem;
// }

// export default SectionFormItem;
