// import React from "react";
// import PropTypes from "prop-types";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Radio from "@mui/material/Radio";
// import { TreeView } from "@mui/x-tree-view/TreeView";
// import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
// import Typography from "@mui/material/Typography";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import JumboCard from "@jumbo/components/JumboCard";
// import { getAssetPath } from "./../../../../utils/appHelpers";
// import { ASSET_IMAGES } from "./../../../../utils/constants/paths";
// import { Checkbox } from "@mui/material";

// const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   [`& .${treeItemClasses.content}`]: {
//     color: theme.palette.text.secondary,
//     borderTopRightRadius: theme.spacing(2),
//     borderBottomRightRadius: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//     fontWeight: theme.typography.fontWeightMedium,
//     "&.Mui-expanded": {
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//     "&:hover": {
//       backgroundColor: theme.palette.action.hover,
//     },
//     "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
//       backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
//       color: "var(--tree-view-color)",
//     },
//     [`& .${treeItemClasses.label}`]: {
//       fontWeight: "inherit",
//       color: "inherit",
//     },
//   },
//   [`& .${treeItemClasses.group}`]: {
//     marginLeft: 0,
//     [`& .${treeItemClasses.content}`]: {
//       paddingLeft: theme.spacing(2),
//     },
//   },
// }));

// const FolderIcon = () => (
//   <img
//     src={getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")}
//     alt="folder icon"
//     width={30}
//     style={{
//       marginRight: "10px",
//     }}
//   />
// );

// function StyledTreeItem(props) {
//   const {
//     bgColor,
//     color,
//     labelIcon: LabelIcon,
//     labelInfo,
//     labelText,
//     isMove,
//     isUpload,
//     ...other
//   } = props;

//   return (
//     <StyledTreeItemRoot
//       label={
//         <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
//           {isMove && (
//             <Radio
//               sx={{ marginRight: "10px" }}
//               // Optional: Add other props for the radio button like onChange, checked, etc.
//             />
//           )}
//           {isUpload && <Checkbox sx={{ marginRight: "10px" }} />}
//           <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
//           <Typography
//             variant="body2"
//             sx={{ fontWeight: "inherit", flexGrow: 1 }}
//           >
//             {labelText}
//           </Typography>
//           <Typography variant="caption" color="inherit">
//             {labelInfo}
//           </Typography>
//         </Box>
//       }
//       style={{
//         "--tree-view-color": color,
//         "--tree-view-bg-color": bgColor,
//       }}
//       {...other}
//     />
//   );
// }

// StyledTreeItem.propTypes = {
//   bgColor: PropTypes.string,
//   color: PropTypes.string,
//   labelIcon: PropTypes.elementType.isRequired,
//   labelInfo: PropTypes.string,
//   labelText: PropTypes.string.isRequired,
//   isMove: PropTypes.bool,
//   isUpload: PropTypes.bool, // Added isUpload prop type
// };

// const renderTreeItems = (nodes, setSelectedFolderId, isMove, isUpload) => {
//   return nodes.map((node) => (
//     <StyledTreeItem
//       key={node.id}
//       nodeId={node.id}
//       labelText={node.folderName}
//       labelIcon={FolderIcon}
//       isMove={isMove}
//       isUpload={isUpload}
//       onClick={() => setSelectedFolderId(node.id)}
//     >
//       {node.children &&
//         renderTreeItems(node.children, setSelectedFolderId, isMove, isUpload)}
//     </StyledTreeItem>
//   ));
// };

// const GmailTreeView = ({ folders, setSelectedFolderId, isMove, isUpload }) => {
//   const buildTree = (nodes, parentId = null) => {
//     if (!Array.isArray(nodes)) return [];
//     return nodes
//       .filter((node) => node.parentId === parentId)
//       .map((node) => ({
//         ...node,
//         children: buildTree(nodes, node.id),
//       }));
//   };

//   const folderTree = buildTree(folders);

//   return (
//     <JumboCard wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
//       <TreeView
//         aria-label="gmail"
//         defaultCollapseIcon={<ArrowDropDownIcon />}
//         defaultExpandIcon={<ArrowRightIcon />}
//         defaultEndIcon={<div style={{ width: 24 }} />}
//         sx={{ height: 440, flexGrow: 1, maxWidth: 450, overflowY: "auto" }}
//       >
//         {renderTreeItems(folderTree, setSelectedFolderId, isMove, isUpload)}
//       </TreeView>
//     </JumboCard>
//   );
// };

// GmailTreeView.propTypes = {
//   folders: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       userId: PropTypes.string.isRequired,
//       folderName: PropTypes.string.isRequired,
//       description: PropTypes.string,
//       folderDriveId: PropTypes.string,
//       oneDriveAuthId: PropTypes.string,
//       status: PropTypes.string,
//       parentId: PropTypes.string,
//       isProject: PropTypes.bool,
//       createdAt: PropTypes.string,
//       updatedAt: PropTypes.string,
//     })
//   ),
//   setSelectedFolderId: PropTypes.func.isRequired,
//   isMove: PropTypes.bool,
//   isUpload: PropTypes.bool, // Added isUpload prop type
// };

// GmailTreeView.defaultProps = {
//   folders: [],
//   isMove: false,
//   isUpload: false, // Default to false
// };

// export default GmailTreeView;

// import React from "react";
// import PropTypes from "prop-types";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Radio from "@mui/material/Radio";
// import { TreeView } from "@mui/x-tree-view/TreeView";
// import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
// import Typography from "@mui/material/Typography";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import JumboCard from "@jumbo/components/JumboCard";
// import { getAssetPath } from "./../../../../utils/appHelpers";
// import { ASSET_IMAGES } from "./../../../../utils/constants/paths";
// import { Checkbox } from "@mui/material";

// // ... [StyledTreeItemRoot and FolderIcon remain unchanged]

// const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   [`& .${treeItemClasses.content}`]: {
//     color: theme.palette.text.secondary,
//     borderTopRightRadius: theme.spacing(2),
//     borderBottomRightRadius: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//     fontWeight: theme.typography.fontWeightMedium,
//     "&.Mui-expanded": {
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//     "&:hover": {
//       backgroundColor: theme.palette.action.hover,
//     },
//     "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
//       backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
//       color: "var(--tree-view-color)",
//     },
//     [`& .${treeItemClasses.label}`]: {
//       fontWeight: "inherit",
//       color: "inherit",
//     },
//   },
//   [`& .${treeItemClasses.group}`]: {
//     marginLeft: 0,
//     [`& .${treeItemClasses.content}`]: {
//       paddingLeft: theme.spacing(2),
//     },
//   },
// }));

// const FolderIcon = () => (
//   <img
//     src={getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")}
//     alt="folder icon"
//     width={30}
//     style={{
//       marginRight: "10px",
//     }}
//   />
// );

// function StyledTreeItem(props) {
//   const {
//     bgColor,
//     color,
//     labelIcon: LabelIcon,
//     labelInfo,
//     labelText,
//     isMove,
//     isUpload,
//     ...other
//   } = props;

//   return (
//     <StyledTreeItemRoot
//       label={
//         <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
//           {isMove && (
//             <Radio
//               sx={{ marginRight: "10px" }}
//               // Optional: Add other props for the radio button like onChange, checked, etc.
//             />
//           )}
//           {isUpload && <Checkbox sx={{ marginRight: "10px" }} />}
//           <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
//           <Typography
//             variant="body2"
//             sx={{ fontWeight: "inherit", flexGrow: 1 }}
//           >
//             {labelText}
//           </Typography>
//           <Typography variant="caption" color="inherit">
//             {labelInfo}
//           </Typography>
//         </Box>
//       }
//       style={{
//         "--tree-view-color": color,
//         "--tree-view-bg-color": bgColor,
//       }}
//       {...other}
//     />
//   );
// }

// StyledTreeItem.propTypes = {
//   bgColor: PropTypes.string,
//   color: PropTypes.string,
//   labelIcon: PropTypes.elementType.isRequired,
//   labelInfo: PropTypes.string,
//   labelText: PropTypes.string.isRequired,
//   isMove: PropTypes.bool,
//   isUpload: PropTypes.bool,
// };

// const renderTreeItems = (nodes, setSelectedFolderId, isMove, isUpload) => {
//   return nodes.map((node) => (
//     <StyledTreeItem
//       key={node.id}
//       nodeId={node.id}
//       labelText={node.folderName}
//       labelIcon={FolderIcon}
//       isMove={isMove}
//       isUpload={isUpload}
//       onClick={() => setSelectedFolderId(node.id)}
//     >
//       {node.children &&
//         renderTreeItems(node.children, setSelectedFolderId, isMove, isUpload)}
//     </StyledTreeItem>
//   ));
// };

// const GmailTreeView = ({ folders, setSelectedFolderId, isMove, isUpload }) => {
//   return (
//     <JumboCard wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
//       <TreeView
//         aria-label="gmail"
//         defaultCollapseIcon={<ArrowDropDownIcon />}
//         defaultExpandIcon={<ArrowRightIcon />}
//         defaultEndIcon={<div style={{ width: 24 }} />}
//         sx={{ height: 440, flexGrow: 1, maxWidth: 450, overflowY: "auto" }}
//       >
//         {renderTreeItems(folders, setSelectedFolderId, isMove, isUpload)}
//       </TreeView>
//     </JumboCard>
//   );
// };

// GmailTreeView.propTypes = {
//   folders: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       userId: PropTypes.string.isRequired,
//       folderName: PropTypes.string.isRequired,
//       description: PropTypes.string,
//       folderDriveId: PropTypes.string,
//       oneDriveAuthId: PropTypes.string,
//       status: PropTypes.string,
//       parentId: PropTypes.string,
//       isProject: PropTypes.bool,
//       createdAt: PropTypes.string,
//       updatedAt: PropTypes.string,
//       children: PropTypes.array, // Add children prop type
//     })
//   ),
//   setSelectedFolderId: PropTypes.func.isRequired,
//   isMove: PropTypes.bool,
//   isUpload: PropTypes.bool,
// };

// GmailTreeView.defaultProps = {
//   folders: [],
//   isMove: false,
//   isUpload: false,
// };

// export default GmailTreeView;

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import JumboCard from "@jumbo/components/JumboCard";
import { getAssetPath } from "./../../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../../utils/constants/paths";

// Styled TreeItem using Material-UI's styled API
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

// FolderIcon Component
const FolderIcon = () => (
  <img
    src={getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")}
    alt="folder icon"
    width={30}
    style={{
      marginRight: "10px",
    }}
  />
);

// StyledTreeItem Component
function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    isMove,
    isUpload,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          {isMove && (
            <Radio
              sx={{ marginRight: "10px" }}
              // Optional: Add other props for the radio button like onChange, checked, etc.
            />
          )}
          {isUpload && <Checkbox sx={{ marginRight: "10px" }} />}
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          {labelInfo && (
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          )}
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

// PropTypes for StyledTreeItem
StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  isMove: PropTypes.bool,
  isUpload: PropTypes.bool,
};

// Recursive function to render tree items with defensive checks
const renderTreeItems = (nodes, setSelectedFolderId, isMove, isUpload) => {
  // Ensure nodes is an array
  if (!Array.isArray(nodes)) {
    console.error("Expected nodes to be an array, but got:", nodes);
    return null; // Optionally, you can return a fallback UI here
  }

  return nodes.map((node) => (
    <StyledTreeItem
      key={node.id}
      nodeId={node.id}
      labelText={node.folderName}
      labelIcon={FolderIcon}
      isMove={isMove}
      isUpload={isUpload}
      onClick={() => setSelectedFolderId(node.id)}
    >
      {/* Recursively render children if they exist and are arrays */}
      {Array.isArray(node.children) && node.children.length > 0
        ? renderTreeItems(node.children, setSelectedFolderId, isMove, isUpload)
        : null}
    </StyledTreeItem>
  ));
};

// Main GmailTreeView Component
const GmailTreeView = ({ folders, setSelectedFolderId, isMove, isUpload }) => {
  return (
    <JumboCard wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
      <TreeView
        aria-label="gmail"
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{ height: 440, flexGrow: 1, maxWidth: 450, overflowY: "auto" }}
      >
        {renderTreeItems(folders, setSelectedFolderId, isMove, isUpload)}
      </TreeView>
    </JumboCard>
  );
};

// PropTypes for GmailTreeView
GmailTreeView.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      folderName: PropTypes.string.isRequired,
      description: PropTypes.string,
      folderDriveId: PropTypes.string,
      oneDriveAuthId: PropTypes.string,
      status: PropTypes.string,
      parentId: PropTypes.string,
      isProject: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      children: PropTypes.array, // Ensuring children is an array
    })
  ),
  setSelectedFolderId: PropTypes.func.isRequired,
  isMove: PropTypes.bool,
  isUpload: PropTypes.bool,
};

// Default Props for GmailTreeView
GmailTreeView.defaultProps = {
  folders: [],
  isMove: false,
  isUpload: false,
};

export default GmailTreeView;
