import React from "react";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import { IconButton, useMediaQuery, Typography, Button } from "@mui/material";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboTheme from "./../../../../../@jumbo/hooks/useJumboTheme";
import { mainTheme as mainThemeDark } from "../../../../themes/main/dark";
import { headerTheme as headerThemeDark } from "../../../../themes/header/dark";
import { footerTheme as footerThemeDark } from "../../../../themes/footer/dark";
import { sidebarTheme as sidebarThemeDark } from "../../../../themes/sidebar/dark";
import { mainTheme as mainThemeDefault } from "../../../../themes/main/default";
import { headerTheme as headerThemeDefault } from "../../../../themes/header/default";
import { footerTheme as footerThemeDefault } from "../../../../themes/footer/default";
import { sidebarTheme as sidebarThemeDefault } from "../../../../themes/sidebar/default";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";
import SearchIcon from "@mui/icons-material/Search";
import { ASSET_LOGOS, ASSET_IMAGES } from "./../../../../utils/constants/paths";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Div from "./../../../../../@jumbo/shared/Div/Div";
import { getAssetPath } from "./../../../../utils/appHelpers";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const [dropdownSearchVisibility, setDropdownSearchVisibility] =
    React.useState(false);
  const { headerTheme } = useJumboHeaderTheme();
  const { theme, setTheme } = useJumboTheme();
  const { setSidebarTheme } = useJumboSidebarTheme();
  const { setHeaderTheme } = useJumboHeaderTheme();
  const { setFooterTheme } = useJumboFooterTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(min-width:576px)");
  const handleGoToUpgrade = () => {
    navigate("/packages");
  };

  const handleModeChange = React.useCallback(async (event) => {
    if (event.target.checked) {
      await setTheme({ mode: "dark", ...mainThemeDark });
      setHeaderTheme({ ...theme, ...headerThemeDark });
      setSidebarTheme({ ...theme, ...sidebarThemeDark });
      setFooterTheme({ ...theme, ...footerThemeDark });
      return;
    }
    await setTheme({ mode: "light", ...mainThemeDefault });
    setHeaderTheme({ ...theme, ...headerThemeDefault });
    setSidebarTheme({ ...theme, ...sidebarThemeDefault });
    setFooterTheme({ ...theme, ...footerThemeDefault });
  }, []);

  const showDropdownSearch = useMediaQuery("(max-width:575px)");

  return (
    <Div
      sx={{
        background: headerTheme?.background,
        padding: "8px 16px",
        boxShadow: "0px 4px 4px 0px #BCB8B840",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
        >
          {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      )}

      <img
        src={`${ASSET_LOGOS}/documanLogo.png`}
        alt="Jumbo React"
        style={{
          width: isSmallScreen ? "72px" : "50px",
          height: isSmallScreen ? "66px" : "50px",
          marginRight: isSmallScreen ? "16px" : "12px",
        }}
      />

      <Stack
        direction="row"
        alignItems="center"
        spacing={1.25}
        sx={{ ml: "auto" }}
      >
        <FormControlLabel
          sx={{ fontSize: { lg: "14px", md: "12px", xs: "10px" } }}
          control={
            <Switch
              checked={theme?.mode === "dark"}
              onChange={handleModeChange}
              name="header-fixed"
            />
          }
          label={
            <Typography
              sx={{
                fontSize: {
                  lg: "14px",
                  md: "12px",
                  xs: "10px",
                },
              }}
            >
              Apply Dark Theme
            </Typography>
          }
          // label="Apply Dark Theme"
        />
        {/* <IconButton> */}
        {/* <SearchIcon /> */}
        {/* </IconButton> */}
        {/* <img
          src={getAssetPath(`${ASSET_IMAGES}/BellIcon.png`, "20x20")}
          alt="pin"
          width={20}
        /> */}
        <Button variant="text" onClick={handleGoToUpgrade}>
          {" "}
          <Typography
            sx={{
              fontSize: {
                lg: "14px",
                md: "12px",
                xs: "10px",
              },
            }}
          >
            Upgrade
          </Typography>
        </Button>
        {/* <MessagesDropdown />
        <NotificationsDropdown /> */}
        <AuthUserDropdown />
      </Stack>
    </Div>
  );
};

export default Header;
