/**
 * @format
 */

import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "@jumbo/constants/ErrorMessages";

const defaultValues = {
  workspaceName: "",
  employeeStrengthId: "",
  industryId: "",
  industryDepartmentId: "",
  userId: "",
  code: "",
};

const schema = Yup.object().shape({
  workspaceName: Yup.string().required("Please select Workspace"),
});

const useQuestionForm = (onSubmit) => {
  return useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });
};

export default useQuestionForm;
