// import React from "react";
// import { Search, SearchIconWrapper, StyledInputBase } from "./style";
// import SearchIcon from "@mui/icons-material/Search";
// import { useDebouncedCallback } from "beautiful-react-hooks";

// const SearchGlobal = ({ sx, inputsx, value, onChange }) => {
//   const [searchKeywords, setSearchKeywords] = React.useState();

//   const handleChange = useDebouncedCallback((event) => {
//     setSearchKeywords(event.target.value);
//   });

//   React.useEffect(() => {
//     onChange(searchKeywords);
//   }, [searchKeywords]);

//   React.useEffect(() => {
//     return () => handleChange.cancel();
//   });
//   return (
//     <Search sx={sx}>
//       <SearchIconWrapper>
//         <SearchIcon />
//       </SearchIconWrapper>

//       <StyledInputBase
//         placeholder="Search anything"
//         inputProps={{ "aria-label": "search" }}
//         sx={inputsx}
//         onChange={handleChange}
//         value={value}
//       />
//     </Search>
//   );
// };

// export default SearchGlobal;

import React from "react";
import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import SearchIcon from "@mui/icons-material/Search";
import { useDebouncedCallback } from "beautiful-react-hooks";

const SearchGlobal = ({ sx, inputsx, value, onChange }) => {
  // const [searchKeywords, setSearchKeywords] = React.useState();
  const [searchKeywords, setSearchKeywords] = React.useState("");

  const handleChange = useDebouncedCallback((event) => {
    setSearchKeywords(event.target.value);
  }, 300); // Optionally debounce the input by 300ms

  React.useEffect(() => {
    if (onChange) {
      onChange(searchKeywords);
    }
  }, [searchKeywords, onChange]);

  React.useEffect(() => {
    return () => handleChange.cancel();
  }, [handleChange]);

  return (
    <Search sx={sx}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>

      <StyledInputBase
        placeholder="Search anything"
        inputProps={{ "aria-label": "search" }}
        sx={inputsx}
        onChange={handleChange}
        value={value}
      />
    </Search>
  );
};

// Add default prop for onChange
SearchGlobal.defaultProps = {
  onChange: () => {},
};

export default SearchGlobal;
