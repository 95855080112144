import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { favoriteFile, pinnedFile } from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";
import { useSnackbar } from "notistack";

const useDashboardApiAction = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const tryFavoriteFile = async (value) => {
    try {
      const response = await favoriteFile(value);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.favorites]);
        queryClient.invalidateQueries([QueryKeys.document]);
        enqueueSnackbar("File favorite successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });

      return false;
    }
  };

  const tryPinnedFile = async (id) => {
    try {
      const response = await pinnedFile(id);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.pinned]);
        queryClient.invalidateQueries([QueryKeys.document]);
        enqueueSnackbar("File Pinned successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  return {
    tryFavoriteFile,
    tryPinnedFile,
  };
};

export { useDashboardApiAction };
