import React, { useState } from "react";
import { users } from "../../data";
import UserItem from "./UserItem";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import TablePagination from "@mui/material/TablePagination";
import PageHeader from "../../../../../layouts/shared/headers/PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useFetchAllUsers } from "../../query/useFetchAllUser";
import useDecodedData from "app/hooks/useDecodedData";
import JumboCustomLoader from "./../../../../../../@jumbo/components/JumboLoader/JumboCustomLoader";

const UsersList = () => {
  const navigate = useNavigate();

  const [userList, setUserList] = useState(users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const decode = useDecodedData();
  console.log("decode", decode);

  const { data: userData, isLoading, refetch } = useFetchAllUsers();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refetch();
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    refetch();
  };

  const handleAddUser = () => {
    navigate(`/dashboards/users/add-user`);
  };

  const paginatedData = Array.isArray(userData)
    ? userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];

  return (
    <JumboContentLayout
      header={
        <PageHeader
          title={"Users"}
          showAddContactDialog={handleAddUser}
          isAddButton
          buttonText={"Add User"}
        />
      }
    >
      {isLoading ? (
        <JumboCustomLoader isList={true} isCustom={false} />
      ) : paginatedData.length > 0 ? (
        paginatedData.map((user, index) => <UserItem user={user} key={index} />)
      ) : (
        <div>No users available.</div>
      )}

      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={userData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </JumboContentLayout>
  );
};
export default UsersList;
