// CustomTableCell.js
import React from "react";
import { TableCell } from "@mui/material";

function JumboTableHead({ children, ...props }) {
  return (
    <TableCell
      {...props}
      sx={{
        fontFamily: "Inter",
        fontSize: { xl: "12px", lg: "10px", md: "9px", xs: "7px" },
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.025em",
        color: "#9CA3AF",
        ...props.style,
      }}
    >
      {children}
    </TableCell>
  );
}

export default JumboTableHead;
