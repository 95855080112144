import React from 'react';
import List from "@mui/material/List";
import {AvatarGroup, ListItem, ListItemIcon, ListItemText, Typography, CardMedia} from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import Stack from "@mui/material/Stack";
import {getAssetPath} from "../../../../utils/appHelpers";
import { ASSET_IMAGES } from './../../../../utils/constants/paths';



function BrandsCard() {
  return (
    <>
    <List
          disablePadding
          sx={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: theme => theme.spacing(0, -2),
          }}
      >
          <ListItem
              sx={{
                  width: { xs: '100%', sm: '50%', xl: '20%' }
              }}
          >
                <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/interwork_new.png`, "640x920")}
                alt="img"
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
          </ListItem>
          <ListItem
              sx={{
                  width: { xs: '100%', sm: '50%', xl: '20%' }
              }}
          >
<CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/Deloitte_new.png`, "640x920")}
                alt="img"
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
   
              
            />


          </ListItem>
          <ListItem
              sx={{
                  width: { xs: '100%', sm: '50%', xl: '20%' }
              }}
          >
            
            <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/insight1.png`, "540x820")}
                alt="img"
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
   
              
            />
          </ListItem>
          <ListItem
              sx={{
                  width: { xs: '100%', sm: '50%', xl: '20%' }
              }}
          >
             
              <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/docu_new.png`, "640x920")}
                alt="img"
                // fontSize={"inherit"} 
                sx={{
                    height: 80, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
         

                  
          </ListItem>
          <ListItem
              sx={{
                  width: { xs: '100%', sm: '50%', xl: '20%' }
              }}
          >
            
              <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/intricity_new.png`, "640x920")}
                alt="img"
                // fontSize={"inherit"} 
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
           
              
          </ListItem>

      </List>
      <Stack  marginTop="30px">
      <List
          disablePadding
          sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent:"center",
              alignItems: 'center',
             
              margin: theme => theme.spacing(0, -2),
          }}
      >
          
              <ListItem
                  sx={{
                      width: { xs: '100%', sm: '50%', xl: '20%' }
                  }}
              >
                 
                  <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/union.webp`, "640x920")}
                alt="img"
                // fontSize={"inherit"} 

                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
              
              </ListItem>
              <ListItem
                  sx={{
                      width: { xs: '100%', sm: '50%', xl: '20%' }
                  }}
              >
               
                  <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/Slalom-wordmark.png`, "640x920")}
                alt="img"
                // fontSize={"inherit"} 
                sx={{
                    height: 70, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
                 
                 
              </ListItem>
              <ListItem
                  sx={{
                      width: { xs: '100%', sm: '50%', xl: '20%' }
                  }}
              >
                 
                  <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/Sophos-Logo.wine.png`, "640x920")}
                alt="img"
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
                // fontSize={"inherit"} 
              
            />
              
                 
              </ListItem>
              <ListItem
                  sx={{
                      width: { xs: '100%', sm: '50%', xl: '20%' }
                  }}
              >
               
                  <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/valmet_logo_rgb.webp`, "640x920")}
                alt="img"
                // fontSize={"inherit"} 
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
                  
                 
              </ListItem>

              <ListItem
                  sx={{
                      width: { xs: '100%', sm: '50%', xl: '20%' }
                  }}
              >
                 
                  <CardMedia
                component="img"
                image={getAssetPath(`${ASSET_IMAGES}/apps/PostgreSQL-Logo.jpg`, "640x920")}
                alt="img"
                sx={{
                    height: 120, // Set desired height
                    width: '100%', // Set desired width
                    objectFit: 'cover', // Ensure the image covers the entire area
                  }}
              
            />
               
                 
              </ListItem>
            

          </List>
          </Stack></> )
}

export default BrandsCard