import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { addFolder, updateFolder } from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";
import { useSnackbar } from "notistack";
import { useState } from "react";

const useFolderApiActions = () => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const tryCreateFolder = async (values) => {
    try {
      setIsSubmitting(true);
      const response = await addFolder(values);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.folder]);
        enqueueSnackbar("Folder Created successfully.", {
          variant: "success",
        });
        navigate("/dashboards/workspace");
      }
      return response;
    } catch (err) {
      const msg = err.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });

      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  const tryUpdateFolder = async (id, values) => {
    try {
      setIsSubmitting(true);
      const response = await updateFolder(id, values);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.folder]);
        enqueueSnackbar("Folder Updated successfully.", {
          variant: "success",
        });
        navigate("/dashboards/workspace");
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    tryCreateFolder,
    tryUpdateFolder,
    isSubmitting,
  };
};

export { useFolderApiActions };
