/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchAllControl } from "../../../../services/pages.services";

import { QueryKeys } from "../../../../utils/QueryKeys";

async function getAllControl() {
  try {
    const response = await fetchAllControl();
    console.log("roles data", response);
    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchAllControl = (enabled = true) => {
  const cacheKey = [QueryKeys.control];
  return useQuery(
    cacheKey,
    () => {
      return getAllControl();
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchAllControl };
