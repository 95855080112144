// import React from "react";
// import Stack from "@mui/material/Stack";
// import Badge from "@mui/material/Badge";
// import Avatar from "@mui/material/Avatar";
// import {
//   Card,
//   IconButton,
//   Typography,
//   ListItemIcon,
//   ListItemText,
//   TableCell,
//   TableRow,
//   Checkbox,
//   Box,
// } from "@mui/material";
// import styled from "@emotion/styled";
// import Span from "@jumbo/shared/Span";
// import { useNavigate } from "react-router-dom";
// import { getAssetPath } from "../../../../../utils/appHelpers";
// import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
// import LockIcon from "@mui/icons-material/Lock";
// import JumboBookmark from "@jumbo/components/JumboBookmark";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import FileIcon from "./../../../workspace/components/FileIcon/index";
// import moment from "moment";
// import { useSnackbar } from "notistack";
// import { useDashboardApiAction } from "app/pages/dashboards/misc/query/useDashboardApiAction";

// const Item = styled(Span)(({ theme }) => ({
//   //   padding: theme.spacing(0, 1),
//   //   display: "flex",
//   //   flexDirection: "row",
//   //   alignItems: "center",
// }));

// const FilesListItem = ({ user, isSelected, handleCheckboxChange, refetch }) => {
//   const navigate = useNavigate();
//   const { enqueueSnackbar } = useSnackbar();
//   const { tryFavoriteFile } = useDashboardApiAction();

//   const showEditUser = () => {
//     navigate(`/dashboards/users/edit-user/${user.id}`);
//   };

//   const handleFavorite = async () => {
//     try {
//       const result = await tryFavoriteFile({ fileId: user?.file?.id });
//       console.log("Favorite file result:", result);
//       if (result) {
//         refetch();
//       }

//       console.log("Document upload successful");
//     } catch (error) {
//       console.error("Error uploading document:", error);
//     }
//   };

//   const handleGoToFile = () => {
//     navigate(`/dashboards/file-details/${user?.file?.id}`);
//   };

//   const fileSize = Math.floor(user?.file?.size / (20 * 20));

//   return (
//     <Card sx={{ mb: 1 }}>
//       <TableRow>
//         <TableCell padding="checkbox">
//           <Checkbox
//             checked={isSelected}
//             onChange={() => handleCheckboxChange(user.id)}
//           />
//         </TableCell>
//         <TableCell
//           align="left"
//           sx={{ width: { lg: "30%", md: "30%", xs: "60%" } }}
//         >
//           <Stack direction={"row"} alignItems={"center"}>
//             <Item>
//               <FileIcon type={user?.file?.fileType} />
//             </Item>
//             <Item
//               sx={{
//                 flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
//                 ml: 1,
//                 cursor: "pointer",
//               }}
//               onClick={
//                 user?.file?.checkInCheckOutStatus !== "checkout"
//                   ? handleGoToFile
//                   : () => {
//                       enqueueSnackbar("The file is checkout", {
//                         variant: "error",
//                       });
//                     }
//               }
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Roboto",
//                   fontSize: { lg: "14px", md: "12px", xs: "10px" },
//                   fontWeight: 500,
//                   lineHeight: "31px",
//                 }}
//               >
//                 {user.file?.fileName}
//               </Typography>
//               <Typography
//                 sx={{
//                   fontFamily: "Roboto",
//                   fontSize: { lg: "12px", md: "10px", xs: "8px" },
//                   fontWeight: 500,
//                   lineHeight: "31px",
//                   color: "#A5A5A5",
//                 }}
//               >
//                 {fileSize} MB
//               </Typography>
//             </Item>
//             <Box
//               display="flex"
//               flexDirection={{ xs: "column", md: "column", lg: "row" }}
//               alignItems="center"
//               sx={{ mt: { md: 1, lg: "-12px", xl: "-12px" } }} // Add margin-top for md to create space when in column
//             >
//               <JumboBookmark
//                 value={true}
//                 sx={{ verticalAlign: "middle" }}
//                 onChange={handleFavorite}
//               />

//               {/* <img
//                 src={getAssetPath(`${ASSET_IMAGES}/shareIcon.png`, "16x16")}
//                 alt="pin"
//                 width={16}
//                 style={{ verticalAlign: "middle" }}
//               /> */}
//             </Box>
//             {user?.file?.checkInCheckOutStatus === "checkout" && (
//               <LockIcon
//                 color="primary"
//                 sx={{ marginLeft: "20px", mt: "-20px" }}
//               />
//             )}
//           </Stack>
//         </TableCell>
//         <TableCell
//           align="left"
//           sx={{
//             width: "20%",
//             display: { lg: "table-cell", md: "table-cell", xs: "none" },
//           }}
//         >
//           <Typography
//             sx={{
//               fontSize: { lg: "12px", md: "10px", xs: "10px" },
//               fontWeight: 500,
//               lineHeight: "16px",
//               letterSpacing: " 0.025em",
//               color: "#9CA3AF",
//             }}
//           >
//             {moment(user?.file?.createdAt).format("MMMM DD")}
//           </Typography>
//         </TableCell>
//         <TableCell
//           align="Left"
//           sx={{
//             width: "20%",
//             display: { lg: "table-cell", md: "table-cell", xs: "none" },
//           }}
//         >
//           <Typography
//             mb={0.5}
//             sx={{
//               fontSize: { lg: "12px", md: "10px", xs: "10px" },
//               fontWeight: 500,
//               lineHeight: "16px",
//               letterSpacing: " 0.025em",
//               color: "#9CA3AF",
//             }}
//           >
//             {user.file.version}
//           </Typography>
//         </TableCell>
//         <TableCell
//           align="left"
//           sx={{
//             width: { lg: "30%", xs: "40%", md: "30%" },
//           }}
//         >
//           <Stack direction={"row"} alignItems={"center"}>
//             <Item>
//               <img
//                 src={getAssetPath(`${ASSET_IMAGES}/editIcon.png`, "24x24")}
//                 alt="pin"
//                 width={24}
//               />
//             </Item>
//             <Item>
//               {" "}
//               <Typography
//                 mb={0.5}
//                 sx={{
//                   ml: 1,
//                   fontSize: { lg: "12px", md: "10px", xs: "10px" },
//                   fontWeight: 500,
//                   lineHeight: "16px",
//                   letterSpacing: " 0.025em",
//                   color: "#9CA3AF",
//                 }}
//               >
//                 {user.user.firstName + " " + user.user.lastName}
//               </Typography>
//             </Item>
//           </Stack>
//         </TableCell>
//       </TableRow>
//     </Card>
//   );
// };

// export default FilesListItem;

import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  Card,
  Typography,
  TableCell,
  TableRow,
  Checkbox,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { useNavigate } from "react-router-dom";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import LockIcon from "@mui/icons-material/Lock";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import FileIcon from "./../../../workspace/components/FileIcon/index";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useDashboardApiAction } from "app/pages/dashboards/misc/query/useDashboardApiAction";

const Item = styled(Span)(({ theme }) => ({
  // Your styles here
}));

const FilesListItem = ({ user, isSelected, handleCheckboxChange, refetch }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tryFavoriteFile } = useDashboardApiAction();

  const [isFav, setIsFav] = useState(user.isFavourite);

  const handleFavorite = async () => {
    // Optimistically update the UI
    setIsFav(!isFav);
    try {
      const result = await tryFavoriteFile({ fileId: user?.file?.id });
      if (result) {
        refetch(); // Re-fetch to ensure data consistency
      } else {
        // If backend update failed, revert the UI
        setIsFav(isFav);
        enqueueSnackbar("Failed to update favorite status", {
          variant: "error",
        });
      }
    } catch (error) {
      // Revert the UI on error
      setIsFav(isFav);
      console.error("Error updating favorite status:", error);
      enqueueSnackbar("Error updating favorite status", { variant: "error" });
    }
  };
  const handleGoToFile = () => {
    navigate(`/dashboards/file-details/${user?.file?.id}`);
  };

  const fileSizeMB = (user?.file?.size / (1024 * 1024)).toFixed(2); // Converts bytes to MB with 2 decimal places

  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
          // checked={isSelected}
          // onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "40%", md: "40%", xs: "60%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <FileIcon type={user?.file?.fileType} />
            </Item>
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
                cursor: "pointer",
              }}
              onClick={
                user?.file?.checkInCheckOutStatus !== "checkout"
                  ? handleGoToFile
                  : () => {
                      enqueueSnackbar("The file is checked out", {
                        variant: "error",
                      });
                    }
              }
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                }}
              >
                {user.file?.fileName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                  color: "#A5A5A5",
                }}
              >
                {fileSizeMB} MB
              </Typography>
            </Item>
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
              alignItems="center"
              sx={{ mt: { md: 1, lg: "-12px", xl: "-12px" } }}
            >
              <JumboBookmark
                value={isFav} // Use local state
                sx={{ verticalAlign: "middle" }}
                onChange={handleFavorite}
              />
              {user?.file?.checkInCheckOutStatus === "checkout" && (
                <LockIcon
                  color="primary"
                  sx={{
                    marginLeft: { xl: "20px", md: "0px", xs: "0px", lg: "0px" },
                  }}
                />
              )}
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "20%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "12px", md: "10px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {moment(user?.file?.createdAt).format("MMMM DD")}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "20%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            mb={0.5}
            sx={{
              fontSize: { lg: "12px", md: "10px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user.file.version}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: { xl: "30%", xs: "40%", md: "30%", lg: "25%" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/editIcon.png`, "24x24")}
                alt="edit"
                width={24}
              />
            </Item>
            <Item>
              <Typography
                mb={0.5}
                sx={{
                  ml: 1,
                  fontSize: { lg: "12px", md: "10px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: " 0.025em",
                  color: "#9CA3AF",
                }}
              >
                {user.user.firstName + " " + user.user.lastName}
              </Typography>
            </Item>
          </Stack>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
