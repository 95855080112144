import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Card,
  useMediaQuery,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { users } from "../data";
import TablePagination from "@mui/material/TablePagination";
import FilesListItem from "./FileListItem";
import JumboTableHead from "./../../../../../../@jumbo/components/JumboTableHead/JumboTableHead";
import { useFetchTrashFileFolder } from "../../query/useFetchTrashFileFolder";
import JumboCustomLoader from "./../../../../../../@jumbo/components/JumboLoader/JumboCustomLoader";

function FilesList({
  selectedUsers,
  onDocumentSelect,
  type,
  refreshList,
  handleSelectedId,
  trashData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isLoading,
}) {
  const [userList, setUserList] = useState(users);
  // const [query, setQuery] = useState("");

  // const [page, setPage] = useState(1);
  // // const type = "file";
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // const {
  //   data: trashData,
  //   isLoading: isLoadingTrash,
  //   refetch: trashRefetch,
  // } = useFetchTrashFileFolder(type, page, limit, query, false);

  // React.useEffect(() => {
  //   if (type || refreshList) {
  //     trashRefetch();
  //   }
  // }, [type, refreshList]);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((user) => user.id);
      // onDocumentSelect(newSelecteds, trashRefetch);
      onDocumentSelect(newSelecteds);
      return;
    }
    onDocumentSelect([]);
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    onDocumentSelect(newSelected);
    handleSelectedId(id);
  };

  const isSelected = (id) => selectedUsers.indexOf(id) !== -1;
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery("(min-width:768px)");

  return (
    <Table>
      <Card sx={{ mb: 1 }}>
        {" "}
        <TableHead>
          <TableRow>
            <JumboTableHead padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedUsers.length > 0 &&
                  selectedUsers.length < userList.length
                }
                checked={
                  userList.length > 0 &&
                  selectedUsers.length === userList.length
                }
                onChange={handleSelectAllClick}
              />
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{ width: isLargeScreen || isMediumScreen ? "40%" : "60%" }}
            >
              <TableSortLabel>
                NAME
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "20%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                DELETED DATE
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "20%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                DELETED BY
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{ width: isLargeScreen || isMediumScreen ? "20%" : "40%" }}
            >
              <TableSortLabel>
                CREATED BY
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
          </TableRow>
        </TableHead>
      </Card>

      <TableBody>
        {isLoading ? (
          <JumboCustomLoader isList={true} isCustom={false} />
        ) : Array.isArray(trashData?.items) && trashData?.items.length > 0 ? (
          trashData?.items?.map((user, index) => (
            <FilesListItem
              user={user}
              key={index}
              isSelected={isSelected(user.id)}
              handleCheckboxChange={handleCheckboxChange}
              type={type}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={100}
              >
                <Typography>No files available</Typography>
              </Box>
            </TableCell>
          </TableRow>
        )}
        {/* {trashData?.items?.map((user, index) => (
          <FilesListItem
            user={user}
            key={index}
            isSelected={isSelected(user.id)}
            handleCheckboxChange={handleCheckboxChange}
            type={type}
          />
        ))} */}
      </TableBody>

      {/* <TablePagination
              component="div"
              count={totalRecords || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[20, 30, 50]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={trashData?.meta.totalItems || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Table>
  );
}

export default FilesList;
