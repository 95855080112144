/**
 * @format
 */
import {useSelector} from "react-redux";


function useDecodedData() {
  const storeData = useSelector((state) => state.user);
  const { userInfo } = storeData;
  return userInfo;
}

export default useDecodedData;
