import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "../store";

const initialState = {
  token: "",
  isLoggedIn: false,
  userInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserCredential: (state, action) => {
      const newState = state;
      newState.isLoggedIn = action.payload.isLoggedIn;
      newState.token = action.payload.token;
    },
    setUserInfo: (state, action) => {
      const newState = state;
      const { userInfo } = action.payload;
      let newData = null;

      // if (userInfo?.role === 1) {
        newData = {
          ...userInfo,
          profilePic: userInfo?.profilePic,
        };
      newState.userInfo = newData;
    },
  },
});

export const { setUserCredential, setUserInfo } = authSlice.actions;

export const useAuthActions = () => {
  const dispatch = useAppDispatch();
  return {
    setUserCredential: (payload) => dispatch(setUserCredential(payload)),
    setUserInfo: (payload) => dispatch(setUserInfo(payload)),
  };
};

const { reducer } = authSlice;
export default reducer;

