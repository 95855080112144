import React, { useState } from "react";

import FoldersList from "./components/FolderList/FolderList";
import DashboardLayout from "./../../../layouts/dashboard-layout/DashboardLayout";

function MiscDashboard() {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [query, setQuery] = useState("");

  console.log("query---->", query);

  const handleDocumentSelection = (selected) => {
    setSelectedUsers(selected);
  };

  const handleQuery = (query) => {
    setQuery(query);
  };
  return (
    <DashboardLayout
      selectedUsers={selectedUsers}
      isFolderView={true}
      isGridView={true}
      handleQuery={handleQuery}
    >
      <FoldersList
        selectedUsers={selectedUsers}
        onDocumentSelect={handleDocumentSelection}
        query={query}
      />
    </DashboardLayout>
  );
}

export default MiscDashboard;
