/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchAllFolder } from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";

async function getAllFolder() {
  try {
    const response = await fetchAllFolder();

    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchAllFolder = (enabled = true) => {
  const cacheKey = [QueryKeys.folder];
  return useQuery(
    cacheKey,
    () => {
      return getAllFolder();
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchAllFolder };
