import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
// import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import { persistor, store } from "./redux/store";
// import { persistor, store } from "redux/store";

import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import "./services/apis";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import JumboAuthProvider from "../@jumbo/components/JumboAuthProvider/JumboAuthProvider";

// const queryClient = new QueryClient();

// const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AppProvider>
            <JumboApp activeLayout={config.defaultLayout}>
              <JumboTheme init={config.theme}>
                <JumboRTL>
                  <JumboDialogProvider>
                    <JumboDialog />
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      maxSnack={3}
                    >
                      <JumboAuthProvider>
                        {/* <QueryClientProvider client={queryClient}> */}
                        <AppLayout>
                          <Suspense
                            fallback={
                              <Div
                                sx={{
                                  display: "flex",
                                  minWidth: 0,
                                  alignItems: "center",
                                  alignContent: "center",
                                  height: "100%",
                                }}
                              >
                                <CircularProgress sx={{ m: "-40px auto 0" }} />
                              </Div>
                            }
                          >
                            <AppRoutes />
                          </Suspense>
                        </AppLayout>
                        {/* </QueryClientProvider> */}
                      </JumboAuthProvider>
                    </SnackbarProvider>
                  </JumboDialogProvider>
                </JumboRTL>
              </JumboTheme>
            </JumboApp>
          </AppProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
