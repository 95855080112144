import React, { useMemo } from "react";
import {
  FormControlLabel,
  RadioGroup,
  Checkbox,
  FormLabel,
  Radio,
} from "@mui/material";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import Span from "./../../../../../../@jumbo/shared/Span/Span";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import { useEffect } from "react";
import { useState } from "react";

const DynamicFormField = ({ index, onChange }) => {
  const { fieldName, type, options, required, value: fieldValue } = index;

  const [parsedOptions, setParsedOptions] = useState([]);
  const [value, setValue] = useState(fieldValue);

  console.log("Original options string:", options);

  // Remove backslashes and double quotes from the options string
  const cleanedOptions = options.replace(/\\/g, "").replace(/\"/g, "");
  console.log("Cleaned options string:", cleanedOptions);
  useEffect(() => {
    try {
      let cleanedOptions = options.replace(/\\/g, "").replace(/\"/g, "");
      cleanedOptions = cleanedOptions
        .replace(/(\w+):/g, '"$1":')
        .replace(/:([^,\]}]+)/g, ':"$1"');

      const parsed = JSON.parse(cleanedOptions);
      if (Array.isArray(parsed)) {
        setParsedOptions(parsed);
      } else {
        throw new Error("Parsed options is not an array");
      }
    } catch (e) {
      console.error("Failed to parse options:", e);
      setParsedOptions([]);
    }
  }, [options]);

  const menuItems = useMemo(
    () =>
      Array.isArray(parsedOptions)
        ? parsedOptions.map((option) => {
            return { id: option.value, name: option.value };
          })
        : [],
    [parsedOptions]
  );

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(fieldName, event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setValue(event.target.value);

    onChange(fieldName, event.target.value, true);
  };

  switch (type) {
    case "DropDown":
      return (
        <JumboFormLabelTextField
          fullWidth
          isSelect
          id={fieldName}
          name={fieldName}
          menuItems={menuItems}
          labelStyle={{
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          value={value}
          label={fieldName}
          onSelectHandler={handleChange}
          placeholder=""
          type="text"
          required={required}
        />
      );
    case "TextField":
      return (
        <JumboFormLabelTextField
          fullWidth
          labelStyle={{
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={fieldName}
          name={fieldName}
          placeholder="Enter info"
          type="text"
          value={value}
          onChange={handleChange}
          required={required}
        />
      );
    case "RadioButton":
      return (
        <Div sx={{ display: "flex", flexDirection: "column", mt: "10px" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              textTransform: "capitalize",
            }}
          >
            <>
              {fieldName}
              {required ? <span style={{ color: "red" }}>*</span> : null}
            </>
          </FormLabel>
          <Span>
            <RadioGroup name={fieldName} row onChange={handleChange}>
              {parsedOptions.map((option, index) => (
                <FormControlLabel
                  key={index}
                  // checked={}
                  value={option.value}
                  control={<Radio />}
                  label={option.value}
                />
              ))}
            </RadioGroup>
          </Span>
        </Div>
      );
    case "Checkbox":
      return (
        <Div sx={{ display: "flex", flexDirection: "column" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              textTransform: "capitalize",
            }}
          >
            <>
              {fieldName}
              {required ? <span style={{ color: "red" }}>*</span> : null}
            </>
          </FormLabel>
          <Span>
            {parsedOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox name={fieldName} value={option.value} />}
                value={option.value}
                label={option?.value}
                onChange={handleCheckboxChange}
              />
            ))}
          </Span>
        </Div>
      );
    default:
      return null;
  }
};

export default DynamicFormField;
