const { REACT_APP_BASE_URL } = process.env;
// const REACT_APP_BASE_URL = "http://localhost:3005";
const endPoints = {
  login: "login",
  signup: "signup",
  checkEmail: "check-email",
  role: "control-access/create-role-assign-control-access",
  user: "user",
  industry: "industries",
  industryDept: "industry-department",
  empStrength: "employee-strength",
  workspace: "workspace-auth",
  forgetPassword: "forgot-password",
  resetPassword: "reset-password",
  addFolder: "folder-and-files/create-folder",
  updateFolder: "folder-and-files/update-folder",
  folder: "folder-and-files/folder",
  document: "folder-and-files/upload-file",
  checkoutCheckIn: "folder-and-files/checkin-checkout",
  deleteFolder: "folder-and-files/delete-folder",
  folders: "folder-and-files/folders",
  fileByFolderId: "folder-and-files/files-by-folderId",
  addVersions: "folder-and-files/add-new-version-file",
  deleteFiles: "folder-and-files/delete-files",
  trashFileFolder: "folder-and-files/get-recycle-bin",
  fileById: "folder-and-files/file",
  fileByVersionId: "folder-and-files/fileVersion",
  downloadByFileDriveId: "folder-and-files/download-file",
  moveFile: "folder-and-files/move-item",
  restoreFiles: "folder-and-files/restore-items",
  permanentDeleteFile: "folder-and-files/delete-from-recycle-bin",
  control: "control/get-all",
  allRole: "roles/get-all",
  myFiles: "folder-and-files/my-files",
  allFiles: "folder-and-files/all-files",
  pinnedFile: "pin-file",
  favoriteFile: "favourites",
  allFolderData: "folder-and-files/all-folders",
  roleAccess: "control-access/assign-control-access",
  access: "control-access/access",
  roleDelete: "control-access/delete",
};

const API_URLS = {
  LOGIN: `${REACT_APP_BASE_URL}/${endPoints.login}`,
  SIGNUP: `${REACT_APP_BASE_URL}/${endPoints.signup}`,
  CHECK_EMAIL: `${REACT_APP_BASE_URL}/${endPoints.checkEmail}`,
  ROLE: `${REACT_APP_BASE_URL}/${endPoints.role}`,
  ALLROLE: `${REACT_APP_BASE_URL}/${endPoints.allRole}`,
  ADD_USER: `${REACT_APP_BASE_URL}/${endPoints.user}`,
  UPDATE_USER_BY_ID: `${REACT_APP_BASE_URL}/${endPoints.user}`,
  INDUSTRY: `${REACT_APP_BASE_URL}/${endPoints.industry}`,
  INDUSTRYDEPT: `${REACT_APP_BASE_URL}/${endPoints.industryDept}`,
  EMP_STRENGTH: `${REACT_APP_BASE_URL}/${endPoints.empStrength}`,
  WORKSPACE: `${REACT_APP_BASE_URL}/${endPoints.workspace}`,
  FORGET_PASSWORD: `${REACT_APP_BASE_URL}/${endPoints.forgetPassword}`,
  RESET_PASSWORD: `${REACT_APP_BASE_URL}/${endPoints.resetPassword}`,
  ADD_FOLDER: `${REACT_APP_BASE_URL}/${endPoints.addFolder}`,
  FOLDER: `${REACT_APP_BASE_URL}/${endPoints.folder}`,
  DOCUMENT: `${REACT_APP_BASE_URL}/${endPoints.document}`,
  UPDATE_FOLDER: `${REACT_APP_BASE_URL}/${endPoints.updateFolder}`,
  CHECKOUT_CHECKIN: `${REACT_APP_BASE_URL}/${endPoints.checkoutCheckIn}`,
  DELETE_FOLDER: `${REACT_APP_BASE_URL}/${endPoints.deleteFolder}`,
  FOLDERS: `${REACT_APP_BASE_URL}/${endPoints.folders}`,
  FILE_BY_FOLDER_ID: `${REACT_APP_BASE_URL}/${endPoints.fileByFolderId}`,
  ADD_VERSIONS: `${REACT_APP_BASE_URL}/${endPoints.addVersions}`,
  DELETE_FILES: `${REACT_APP_BASE_URL}/${endPoints.deleteFiles}`,
  TRASH_FILE_FOLDER: `${REACT_APP_BASE_URL}/${endPoints.trashFileFolder}`,
  FILE_BY_ID: `${REACT_APP_BASE_URL}/${endPoints.fileById}`,
  FILE_BY_VERSION_ID: `${REACT_APP_BASE_URL}/${endPoints.fileByVersionId}`,
  DOWNLOAD_BY_FILEDRIVEID: `${REACT_APP_BASE_URL}/${endPoints.downloadByFileDriveId}`,
  MOVE_FILE: `${REACT_APP_BASE_URL}/${endPoints.moveFile}`,
  RESTORE_FILES: `${REACT_APP_BASE_URL}/${endPoints.restoreFiles}`,
  DELETE_PERMANENT_FILE: `${REACT_APP_BASE_URL}/${endPoints.permanentDeleteFile}`,
  CONTROL: `${REACT_APP_BASE_URL}/${endPoints.control}`,
  MY_FILES: `${REACT_APP_BASE_URL}/${endPoints.myFiles}`,
  All_FILES: `${REACT_APP_BASE_URL}/${endPoints.allFiles}`,
  PINNED_FILE: `${REACT_APP_BASE_URL}/${endPoints.pinnedFile}`,
  FAVORITE_FILE: `${REACT_APP_BASE_URL}/${endPoints.favoriteFile}`,
  All_FOLDER_DATA: `${REACT_APP_BASE_URL}/${endPoints.allFolderData}`,
  EDIT_ROLE: `${REACT_APP_BASE_URL}/${endPoints.roleAccess}`,
  DELETE_ROLE: `${REACT_APP_BASE_URL}/${endPoints.roleDelete}`,
  ACCESS: `${REACT_APP_BASE_URL}/${endPoints.access}`,
};
export default API_URLS;
