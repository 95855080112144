import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import Div from "@jumbo/shared/Div";
import FeaturesList from "./components/FeaturesList";
import PricePlan from "./components/PricePlan";
import { useNavigate } from "react-router-dom";

const Packages = ({ scrollHeight }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  const handleGoToDashboard = () => {
    navigate("/dashboards/misc");
  };

  return (
    <>
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Roboto",
              fontSize: { lg: "24px", md: "20px", xs: "15px" },
              fontWeight: 500,
              lineHeight: "28.13px",
              textAlign: "left",
              mb: "12px",
            }}
          >
            Try out the best of Documan for free
          </Typography>
        </Div>

        <Typography
          sx={{
            color: "#363636",
            fontFamily: "Roboto",
            fontSize: { lg: "14px", md: "12px", xs: "12px" },
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          We’ll remind you when you have a few days left in your trial
        </Typography>
        <Button
          variant="text"
          sx={{
            position: "absolute",
            top: { lg: "0px", md: "0px", xs: "-25px" },
            right: "5%",
            zIndex: 1,
            textTransform: "none",
            fontFamily: "Roboto",
            fontSize: { lg: "20px", md: "15px", xs: "15px" },
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "left",
            color: "#371B65",
          }}
          onClick={handleGoToDashboard}
        >
          Skip
        </Button>
      </Div>

      <Grid container spacing={3.75} mt={1}>
        <Grid item xs={12} md={4} lg={3.75} xl={3.75}>
          <PricePlan
            title={"Rs. 150"}
            sx={{
              borderRadius: "20px",
              // width: "400px",
              height: "600px",
              gap: "0px",
              opacity: "0px",
              boxShadow: "0px 4px 20px 0px #A9A9A940",
            }}
            subheader={"Personal"}
            headerSx={{
              textAlign: "center",
              bgcolor: " #371B65",
            }}
          >
            <FeaturesList isLoading={isLoading} />
            <Button
              variant={"contained"}
              color={"warning"}
              disableElevation
              sx={{
                mt: "23px",
                backgroundColor: "#371B65",
                width: "149px",
                height: "50px",
                padding: " 5.43px 7.24px 5.43px 7.24px",
                gap: " 7.24px",
                borderRadius: "3.62px 0px 0px 0px",
                opacity: "0px",
                textTransform: "none",
              }}
              // onClick={handleGoToDashboard}
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>
        <Grid item xs={12} md={4} lg={3.75} xl={3.75}>
          <PricePlan
            title={"Rs. 500"}
            sx={{
              borderRadius: "20px",
              // width: "400px",
              height: "585px",
              gap: "0px",
              opacity: "0px",
              boxShadow: "0px 4px 20px 0px #A9A9A940",
              transform: "scale(1)",
            }}
            subheader={"Business"}
            headerSx={{
              textAlign: "center",

              bgcolor: "#371B65",
            }}
          >
            <FeaturesList isLoading={isLoading} isMiddle />
            <Button
              variant={"contained"}
              color={"warning"}
              disableElevation
              sx={{
                mt: "23px",
                backgroundColor: "#371B65",
                width: "149px",
                height: "50px",
                padding: " 5.43px 7.24px 5.43px 7.24px",
                gap: " 7.24px",
                borderRadius: "3.62px 0px 0px 0px",
                opacity: "0px",
                textTransform: "none",
              }}
              // onClick={handleGoToDashboard}
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>

        <Grid item xs={12} md={4} lg={3.75} x={3.75}>
          <PricePlan
            title={"Rs. 250"}
            sx={{
              borderRadius: "20px",
              // width: "400px",
              height: "600px",
              gap: "0px",
              opacity: "0px",
              boxShadow: "0px 4px 20px 0px #A9A9A940",
            }}
            subheader={"Professional"}
            headerSx={{
              textAlign: "center",
              bgcolor: "#371B65",
            }}
          >
            <FeaturesList isLoading={isLoading} />
            <Button
              variant={"contained"}
              color={"warning"}
              disableElevation
              sx={{
                mt: "23px",
                backgroundColor: "#371B65",
                width: "149px",
                height: "50px",
                padding: " 5.43px 7.24px 5.43px 7.24px",
                gap: " 7.24px",
                borderRadius: "3.62px 0px 0px 0px",
                opacity: "0px",
                textTransform: "none",
              }}
              // onClick={handleGoToDashboard}
            >
              Buy Now
            </Button>
          </PricePlan>
        </Grid>
      </Grid>
    </>
  );
};

export default Packages;
