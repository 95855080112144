import { useAppSelector } from "app/redux/store";
import client from "app/services/client";
import API_URLS from "app/services/endPoints";
import { QueryKeys } from "app/utils/QueryKeys";
import { useQuery } from "react-query";

async function getAccess(roleId, pageId) {
  try {
    return client.get(`${API_URLS.ACCESS}/${roleId}/${pageId}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const useAccessControlQuery = (pageCode) => {
  const cacheKey = [QueryKeys.pageAccess, pageCode];
  const { userInfo } = useAppSelector((store) => store.user);

  return useQuery(
    cacheKey,
    () => {
      return getAccess(userInfo?.roleId, pageCode);
    },
    {
      enabled: Boolean(pageCode?.length),
      refetchOnWindowFocus: false,
    }
  );
};
