import React, { useState, useCallback, useEffect } from "react";
import JumboContentLayout from "./../../../../@jumbo/components/JumboContentLayout/JumboContentLayout";
import Header from "./components/Header/Header";
import {
  Card,
  Divider,
  Switch,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import JumboFormLabelTextField from "./../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import Div from "./../../../../@jumbo/shared/Div/Div";
import { useJumboDialog } from "./../../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import FieldSelectionForm from "./components/FieldSelectionForm/FieldSelectionForm";
import useFileForm from "./hooks/useFileForm";
import SectionForm from "./components/SectionForm";
import { useFolderApiActions } from "./query/useFolderApiActions";
import { useSelector } from "react-redux";
import FolderHeader from "./components/FolderHeader/FolderHeader";
import { useFetchFolderById } from "./query/useFetchFolderById";
import { identifier } from "stylis";
import { useSnackbar } from "notistack";

const fieldList = [
  { id: "DropDown", name: "DropDown" },
  { id: "TextField", name: "TextField" },
  { id: "Checkbox", name: "Checkbox" },
  { id: "RadioButton", name: "RadioButton" },
];

function NewContainer() {
  const [sections, setSections] = useState([[]]);

  const { location } = window;

  const id = location.pathname?.split("/")?.[3];
  const actionName = location.pathname?.split("/")?.[2];

  const { hideDialog, showDialog } = useJumboDialog();
  const { tryCreateFolder, tryUpdateFolder, isSubmitting } =
    useFolderApiActions();
  const { data: folderData, refetch: folderRefetch } = useFetchFolderById(
    id,
    false
  );

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    folderName: "",
    description: "",
    parentFolderId: "",
    isProject: true,
    indexGroup: [{}],
  };

  useEffect(() => {
    if (id && actionName === "edit-container") {
      folderRefetch();
    }
  }, [id && actionName === "edit-container"]);

  useEffect(() => {
    if (folderData) {
      setFieldValue("id", id);
      setFieldValue("folderName", folderData.folderName || "");
      setFieldValue("description", folderData.description || "");
      setFieldValue("isProject", folderData.isProject);
      setSections(
        folderData?.indexGroup?.map((group) => ({
          groupTitle: group.groupTitle,
          indexes: group.indexes.map((field) => ({
            ...field,

            options: field.options || "",
          })),
        })) || [{}]
      );
    }
  }, [folderData]);
  const handleSelectChange = useCallback(
    (index) => (event) => {
      const type = event;
      setFieldValue(`indexGroup[0].indexes[${index}].type`, type);
      showDialog({
        title: "More Details",
        content: (
          <FieldSelectionForm
            type={type}
            onConfirm={(fieldDetails) => {
              const newSections = sections?.map((section, idx) =>
                idx === index
                  ? [...section, { type, ...fieldDetails }]
                  : section
              );
              setSections(newSections);
              hideDialog();
            }}
            onCancel={hideDialog}
          />
        ),
      });
    },
    [sections, showDialog, hideDialog]
  );

  const handleAddSection = () => {
    const newSection = {
      groupTitle: "",
      indexes: [
        {
          fieldName: "",
          type: "",
          options: "",
          required: true,
          actions: [],
        },
      ],
    };
    setFieldValue("indexGroup", [...values.indexGroup, newSection]);
    setSections([...sections, []]);
  };

  const updateIndexGroup = (index, updatedGroup) => {
    setFieldValue(`indexGroup[${index}]`, updatedGroup);
  };

  const onSave = (index, updatedGroup) => {
    setFieldValue(`indexGroup[${index}]`, updatedGroup);
  };

  const onSubmit = async (values) => {
    if (values.isProject) {
      const isIndexGroupEmpty =
        !values.indexGroup || values.indexGroup.length === 0;
      if (isIndexGroupEmpty) {
        enqueueSnackbar("Please fill the section form below", {
          variant: "error",
        });
        return;
      }
    }
    if (id && actionName === "edit-container") {
      await tryUpdateFolder(id, values);
    } else if (actionName === "new-container" && id) {
      await tryCreateFolder({ ...values, parentFolderId: id });
    } else {
      await tryCreateFolder(values);
    }
  };

  const removeSection = (index) => {
    setSections(sections.filter((_, ind) => ind != index));
    setFieldValue("indexGroup", [
      ...values.indexGroup.filter((_, ind) => ind != index),
    ]);
  };

  const formik = useFileForm(onSubmit, defaultValues);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <JumboContentLayout
      header={
        <FolderHeader
          title={"Create Folder"}
          handleSave={handleSubmit}
          isSubmitting={isSubmitting}
        />
      }
    >
      <Card
        sx={{ display: "flex", mb: 3.5, padding: 4, flexDirection: "column" }}
      >
        <Div
          sx={{ display: "flex", flexDirection: "row", flex: "1", gap: "64px" }}
        >
          <Div sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <JumboFormLabelTextField
              fullWidth
              labelStyle={{
                color: "#000000",
                fontSize: { xs: "1.2rem", md: "16px" },
                fontWeight: 400,
              }}
              label="Folder Name"
              name="folderName"
              placeholder="Enter folder name"
              type="text"
              error={!!touched.folderName && !!errors.folderName}
              helperText={
                (touched.folderName && errors && errors.folderName) || ""
              }
              value={values.folderName}
              onBlur={handleBlur("folderName")}
              onChange={handleChange("folderName")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.isProject}
                  onChange={(e) => handleChange("isProject")(e)}
                  onBlur={handleBlur("isProject")}
                />
              }
              label="Project"
            />
          </Div>
          <JumboFormLabelTextField
            fullWidth
            maxRows={8}
            rows={4}
            name="description"
            multiline
            labelStyle={{
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label="Description"
            placeholder=""
            type="text"
            error={!!touched.description && !!errors.description}
            helperText={
              (touched.description && errors && errors.description) || ""
            }
            value={values.description}
            onBlur={handleBlur("description")}
            onChange={handleChange("description")}
          />
        </Div>
      </Card>
      {values.isProject && (
        <>
          <Header onAddSection={handleAddSection} onAddAction={onSave} />
          {sections.map((section, index) => (
            <SectionForm
              key={index}
              section={section}
              index={index}
              handleAddSection={handleAddSection}
              handleSelectChange={handleSelectChange}
              fieldList={fieldList}
              updateIndexGroup={updateIndexGroup}
              onSave={onSave}
              removeSection={removeSection}
            />
          ))}
        </>
      )}
    </JumboContentLayout>
  );
}

export default NewContainer;
