/**
 * @format
 */

import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "@jumbo/constants/ErrorMessages";

const defaultValues = {
  password: "",
};

const schema = Yup.object().shape({
  password: Yup.string().required(ErrorMessages.login.password),
});

const usePasswordForm = (onSubmit) => {
  return useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });
};

export default usePasswordForm;
