import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  Grid,
  TableHead,
  TableRow,
  TableSortLabel,
  Card,
  Checkbox,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { users } from "../data";
import TablePagination from "@mui/material/TablePagination";
import FolderListItem from "./FolderListItem";
import { useFetchAllFiles } from "app/pages/dashboards/misc/query/useFetchAllFiles";

function FoldersList({ selectedUsers, onDocumentSelect, query }) {
  const [userList, setUserList] = useState(users);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    data: myFileData,
    refetch,
    isLoading,
  } = useFetchAllFiles(page, limit, query);

  console.log("myFileData--->", myFileData?.items);

  useEffect(() => {
    if (
      typeof query === "string" &&
      (query.trim() !== "" || query.trim() === "")
    ) {
      refetch();
    }
  }, [query]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((user) => user.id);
      onDocumentSelect(newSelecteds);
      return;
    }
    onDocumentSelect([]);
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    onDocumentSelect(newSelected);
  };

  const isSelected = (id) => selectedUsers.indexOf(id) !== -1;

  return (
    <Table>
      <Card sx={{ mb: 1 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedUsers.length > 0 &&
                  selectedUsers.length < userList.length
                }
                checked={
                  userList.length > 0 &&
                  selectedUsers.length === userList.length
                }
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell align="left" style={{ width: "40%" }}>
              <TableSortLabel
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: "0.025em",
                  color: "#9CA3AF",
                }}
              >
                NAME
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" style={{ width: "20%" }}>
              <TableSortLabel
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: "0.025em",
                  color: "#9CA3AF",
                }}
              >
                OPENED
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" style={{ width: "20%" }}>
              <TableSortLabel
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: "0.025em",
                  color: "#9CA3AF",
                }}
              >
                OWNER
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" style={{ width: "20%" }}>
              <TableSortLabel
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: "0.025em",
                  color: "#9CA3AF",
                }}
              >
                ACTIVITY
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
      </Card>

      <TableBody>
        <Grid container spacing={3.75}>
          {myFileData?.items?.map((user, index) => (
            <Grid item key={index} xs={12} sm={6} lg={4}>
              <FolderListItem user={user} isFolder={false} />
            </Grid>
          ))}
        </Grid>
      </TableBody>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Table>
  );
}

export default FoldersList;
