import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "../store";

const initialState = {
  accessData: {
    "myFile-001": "",
    "user-001": "",
    "workspace-001": "",
    "settings-001": "",
    "allFile-001": "",
    "recycle-001": "",
    "sharedFile-001": "",
    "favorites-001": "",
  },
};

const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    setaccessData: (state, action) => {
      state.accessData = action.payload;
    },
  },
});

export const { setaccessData } = accessSlice.actions;

export const useAccessActions = () => {
  const dispatch = useAppDispatch();

  const setAccessData = (AccessData) => {
    dispatch(setaccessData(AccessData));
  };

  return {
    setAccessData,
  };
};

const { reducer } = accessSlice;

export default reducer;
