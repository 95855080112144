// sectionData.slice.js
import { createSlice } from "@reduxjs/toolkit";

const sectionDataSlice = createSlice({
  name: "sectionData",
  initialState: {
    sections: [],
  },
  reducers: {
    addSection(state, action) {
      state.sections.push(action.payload);
    },
    updateSection(state, action) {
      const { index, updatedGroup } = action.payload;
      state.sections[index] = updatedGroup;
    },
    removeSection(state, action) {
      state.sections.splice(action.payload, 1);
    },
    // ... other reducers
  },
});

export const { addSection, updateSection, removeSection } =
  sectionDataSlice.actions;

// Selector to get all field labels across all sections
export const selectAllFieldLabels = (state) => {
  return state.sectionData?.sections?.map((section) =>
    section.indexes.map((field) => field.fieldName)
  );
};

export default sectionDataSlice.reducer;
