import React from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import {Accordion, AccordionDetails, AvatarGroup, Card, LinearProgress, Typography} from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

import styled from "@emotion/styled";
import {packageDetail} from "./packageData";
import DataModellingList from './DataModelling/DataModelling';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const PackageDetailItem = ({item}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleClick = () => {
        if (packageDetail.find((product) => product.id === item.id))
            setExpanded(!expanded);
    }
    return (
        <Card sx={{mb: 1}}>
            <Accordion expanded={expanded} onChange={handleClick} square sx={{borderRadius: 2}}>
                <AccordionSummary
                    expandIcon={expanded ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    {/* <Div sx={{flexShrink: 0, px: 1}}>
                        <Avatar sx={{width: 52, height: 52}} alt={item.name} src={item.logo} variant="rounded"/>
                    </Div> */}
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '26%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Typography variant={"h5"} mb={.5} fontSize={14}>{item.name}</Typography>
                       
                    </Div>
                </AccordionSummary>
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 8.25)}}>
             <DataModellingList/>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default PackageDetailItem;