import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outlinedInput: {
    "&$focused $notchedOutline": {
      borderColor: "#AEB4C2", // color when focused
    },
  },
  focused: {},
  notchedOutline: {},
});

const OutlinedSecondaryTextField = (props) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.outlinedInput,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        },
        endAdornment: props.endAdornment,
      }}
      InputLabelProps={{
        style: {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "18px",
          textAlign: "left",
          color: "#5A6684",
        },
        placeholder: props.placeholder, // Set the placeholder here
      }}
      style={{
        width: "100%",
        borderRadius: "8px",
        borderColor: "#AEB4C2",
        ...props.style,
      }}
    >
      {props.children}
    </TextField>
  );
};

export default OutlinedSecondaryTextField;
