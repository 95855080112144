import {TableCell, TableRow} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import React from "react";
import styled from "@emotion/styled";
import CheckIcon from '@mui/icons-material/Check';
import Span from "@jumbo/shared/Span";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));

const DataModellingItem = ({item}) => {

    return (
        <StyledTableRow key={item.name}>
            <TableCell sx={{pl: theme => theme.spacing(3)}}>
                {item.name}
            </TableCell>
            <TableCell align="center"><CheckIcon style={{color:"#00A073"}}/></TableCell>
            <TableCell align="center">
            <CheckIcon style={{color:"#00A073"}}/>
            </TableCell>
            <TableCell align="center" sx={{pr: theme => theme.spacing(3),color:"#00A073" }}><CheckIcon/></TableCell>
        </StyledTableRow>

    );
};

export default DataModellingItem;