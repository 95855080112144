import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useJumboDialog } from "./../../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useFetchAllFolder } from "app/pages/dashboards/workspace/query/useFetchAllFolder";
import GmailTreeView from "./../../../pages/components/mui/Treeviews/GmailTreeView";
import { useNavigate } from "react-router-dom";

const UploadPopup = ({ selectedValue, open, handleClose }) => {
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();
  // const { tryMoveFile } = useWorkspaceApiAction();

  const handleGoToDocument = () => {
    navigate(`/dashboards/upload-document/${selectedFolderId}`);
  };

  // const handleMoveFile = () => {
  //   showDialog({
  //     variant: "confirm",
  //     title: "Are you sure you want to move the file ?",
  //     content: "You won't be able to recover this contact later",
  //     onYes: () => {
  //       // tryMoveFile?.(selectedValue, selectedFolderId);
  //       hideDialog();
  //       handleClose();
  //     },
  //     onNo: hideDialog,
  //   });
  // };

  //   const handleListItemClick = (value) => {
  //     handleClose(value);
  //   };

  console.log("moveid--->", selectedFolderId);

  const { data: folderData } = useFetchAllFolder();
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Set backup account</DialogTitle>
      <DialogContent>
        <GmailTreeView
          isUpload={true}
          folders={folderData}
          setSelectedFolderId={setSelectedFolderId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleGoToDocument}>Upload</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPopup;
