import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

export const menuItems = [
    {
        icon:null,
        title: "View",
        slug: "all",
    },
    {
        icon:null,
        title: "Edit",
        slug: "edit",
    },
    {
        icon:null,
        title: "Delete",
        slug: "delete",
    }
];



export const company = [
    {
        id:"U_123",
companyName: "Sanicon Services",
companyEmail: "saniconService@gmail.com",
companyMobile: "4564321",
companyAddress: "XYz"
      
    },
    {
        id:"U_124",
     
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_125",
      
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_126",
      
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_127",
     
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_128",
   
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_129",
      
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_129",
     
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
    {
        id:"U_130",
   
        companyName: "Sanicon Services",
        companyEmail: "saniconService@gmail.com",
        companyMobile: "4564321",
        companyAddress: "XYz"
    },
];
