import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import {
  Card,
  Typography,
  TableCell,
  TableRow,
  Checkbox,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { useNavigate } from "react-router-dom";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboBookmark from "@jumbo/components/JumboBookmark";
import moment from "moment";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

import FileIcon from "./../../../workspace/components/FileIcon/index";
import { useDashboardApiAction } from "../../query/useDashboardApiAction";
import LockIcon from "@mui/icons-material/Lock";
import { useSnackbar } from "notistack";

const Item = styled(Span)(({ theme }) => ({
  //   padding: theme.spacing(0, 1),
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
}));

const FilesListItem = ({
  user,
  isSelected,
  handleCheckboxChange,
  handlePinned,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tryPinnedFile, tryFavoriteFile } = useDashboardApiAction();
  const fileSizeInMB = (user?.size / (1024 * 1024)).toFixed(2);

  const showEditUser = () => {
    navigate(`/dashboards/users/edit-user/${user.id}`);
  };

  const handleGoToFile = () => {
    navigate(`/dashboards/file-details/${user.id}`);
  };

  const handlePinnedSet = async () => {
    console.log("Image clicked, handling pin...");
    try {
      await tryPinnedFile({ fileId: user.id });
      handlePinned();
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const handleFavorite = async () => {
    try {
      await tryFavoriteFile({ fileId: user.id });
      console.log("Document upload successful");
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
          // checked={isSelected}
          // onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "35%", md: "35%", xs: "60%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <FileIcon type={user?.fileType} />
              {/* <img
                src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "32x32")}
                alt="pin"
                width={32}
              /> */}
            </Item>
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
                cursor: "pointer",
              }}
              onClick={
                user?.checkInCheckOutStatus !== "checkout"
                  ? handleGoToFile
                  : () => {
                      enqueueSnackbar("The file is checkout", {
                        variant: "error",
                      });
                    }
              }

              // onClick={handleGoToFile}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                }}
              >
                {user.fileName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "31px",
                  color: "#A5A5A5",
                }}
              >
                {fileSizeInMB} MB
                {/* {user.size} MB */}
              </Typography>
            </Item>
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
              alignItems="center"
              sx={{ mt: { md: 1, lg: 0 } }} // Add margin-top for md to create space when in column
            >
              <JumboBookmark
                value={user?.isFavourite}
                sx={{ verticalAlign: "middle" }}
                onChange={handleFavorite}
              />
              {user?.isPinned ? (
                <img
                  src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "16x16")}
                  alt="pin"
                  width={18}
                  onClick={handlePinnedSet}
                  style={{
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <PushPinOutlinedIcon
                  sx={{
                    fontSize: "18px",
                    transform: "rotate(52.5deg)",
                    ml: "7px",
                    cursor: "pointer",
                  }}
                  onClick={handlePinnedSet}
                />
              )}
              {user?.checkInCheckOutStatus === "checkout" && (
                <LockIcon
                  color="primary"
                  sx={{
                    marginLeft: { xl: "10px", lg: "0px" },
                    mt: { xl: "-2px", lg: "0px" },
                  }}
                />
              )}

              {/* <img
                src={getAssetPath(`${ASSET_IMAGES}/shareIcon.png`, "16x16")}
                alt="pin"
                width={16}
                style={{ verticalAlign: "middle" }}
              /> */}
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "30%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Typography
              // variant={"h6"}
              // mt={1}
              // lineHeight={1.25}
              sx={{
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              Created at
            </Typography>
            <img
              src={getAssetPath(`${ASSET_IMAGES}/pointIcon.png`, "4x4")}
              alt="pin"
              width={4}
              style={{ marginLeft: "13px", marginRight: "13px" }}
            />
            {/* <FiberManualRecordIcon fontSize="sm" /> */}
            <Typography
              variant={"h6"}
              mt={1}
              lineHeight={1.25}
              sx={{
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              {moment(user?.createdAt).format("MMMM DD, h:mm A")}
              {/* Apr 16, 2024 */}
              {/* {user.email} */}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "15%",
            display: { lg: "table-cell", xs: "none", md: "table-cell" },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <Avatar
                sx={{
                  width: 34,
                  height: 34,
                }}
                alt={`${user.firstName} 
                                    ${user.lastName}`}
                src={user.profilePic}
              />
            </Item>
            <Typography
              sx={{
                marginLeft: 1.5,
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              {user?.user?.firstName} {user?.user?.lastName}
              {/* {user.mobile} */}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: {
              lg: "20%",
              md: "20%",
              xs: "40%",
              display: { lg: "table-cell", md: "none", xs: "none" },
            },
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            {/* <Item>
              <img
                src={getAssetPath(`${ASSET_IMAGES}/driveIcon.png`, "24x24")}
                alt="pin"
                width={24}
              />
            </Item> */}
            <Item>
              {" "}
              <Typography
                variant={"h6"}
                mb={0.5}
                sx={{
                  ml: 1,
                  fontSize: { lg: "12px", md: "10px", xs: "8px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  letterSpacing: " 0.025em",
                  color: "#9CA3AF",
                }}
              >
                {user?.fileType}
                {/* My Drive */}
                {/* {user.companyName} */}
              </Typography>
            </Item>
          </Stack>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
