/**
 * @format
 */

import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

const defaultValues = {
  fieldName: "",
  type: "",
  options: [],
};

const schema = Yup.object().shape({
  fieldName: Yup.string().required("please enter description"),
});

const useIndexPropForm = (onSubmit, initialValues = defaultValues) => {
  return useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useIndexPropForm;
