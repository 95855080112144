import React from "react";
import MiscDashboard from "../pages/dashboards/misc/MiscDashboard";
import MyFiles from "../pages/dashboards/my-files/MyFiles";
import GridDashboard from "../pages/dashboards/dashboard-gridview/DashboardGridView";
import GridFolderDashboard from "app/pages/dashboards/dashboard-gridviewFolder/DashboardGridView";
import Page from "@jumbo/shared/Page";
import Packages from "../pages/dashboards/packages/Packages";
import Settings from "../pages/dashboards/settings/Settings";
import UsersListing from "../pages/dashboards/users/UserListing";
import Company from "../pages/dashboards/company/Company";
import NewDocument from "../pages/dashboards/upload-document/index";
import SharedFiles from "../pages/dashboards/shared-files/SharedFiles";
import FavoriteFiles from "../pages/dashboards/favorites-files/FavoriteFiles";
import TrashFiles from "../pages/dashboards/trash-files/TrashFiles";
import FileDetail from "../pages/dashboards/file-detail/FileDetail";
import NewContainer from "../pages/dashboards/new-container/index";

const AddUserForm = React.lazy(() =>
  import("../pages/dashboards/users/component/UserForm/index")
);
const AddCompanyForm = React.lazy(() =>
  import("../pages/dashboards/company/component/CompanyForm/index")
);
const Workspace = React.lazy(() =>
  import("../pages/dashboards/workspace/index")
);

const dashboardRoutes = [
  // {
  //   path: "/dashboards/packages",
  //   element: <Page component={Packages} />,
  // },
  {
    path: "/dashboards/settings",
    element: <Page component={Settings} code={"settings-001"} />,
    code: "settings-001",
  },
  {
    path: "/dashboards/users",
    element: <Page component={UsersListing} code={"user-001"} />,
    code: "user-001",
  },
  {
    path: "/dashboards/users/:category",
    element: <Page component={UsersListing} code={"user-001"} />,
    code: "user-001",
  },

  {
    path: "dashboards/users/add-user",
    element: <Page component={AddUserForm} code={"user-001"} />,
    code: "user-001",
  },

  {
    path: "dashboards/users/edit-user/:id",
    element: <Page component={AddUserForm} code={"user-001"} />,
    code: "user-001",
  },

  // {
  //   path: [
  //     "/dashboards/company/:category",
  //     "/dashboards/company/:category/:id",
  //   ],
  //   element: <Page component={Company} />,
  // },

  // {
  //   path: "dashboards/company/add-company",
  //   element: <AddCompanyForm />,
  // },

  {
    path: "/dashboards/misc",
    element: <Page component={MiscDashboard} code={"allFile-001"} />,
    code: "allFile-001",
  },

  {
    path: "/dashboards/my-files",
    element: <Page component={MyFiles} code={"myFile-001"} />,
    code: "myFile-001",
  },

  {
    path: "/dashboards/file-details/:id",
    element: <Page component={FileDetail} code={"myFile-001"} />,
    code: "myFile-001",
  },
  {
    path: "/dashboards/file-details/:id/:version",
    element: <Page component={FileDetail} />,
  },
  {
    path: "/dashboards/shared-files",
    element: <Page component={SharedFiles} code={"sharedFile-001"} />,
    code: "sharedFile-001",
  },
  {
    path: "/dashboards/favorite-files",
    element: <Page component={FavoriteFiles} code={"favorites-001"} />,
    code: "favorites-001",
  },

  {
    path: "/dashboards/trash-files",
    element: <Page component={TrashFiles} code={"recycle-001"} />,
    code: "recycle-001",
  },

  {
    path: "/dashboards/misc/grid-view",
    element: <Page component={GridDashboard} code={"allFile-001"} />, //all-file
    code: "allFile-001",
  },

  {
    path: "/dashboards/workspace",
    element: <Page component={Workspace} code={"workspace-001"} />,
    code: "workspace-001",
  },
  {
    path: "/dashboards/workspace/:category",
    element: <Page component={Workspace} code={"workspace-001"} />,
    code: "workspace-001",
  },
  {
    path: "/dashboards/workspace/:category/:id",
    element: <Page component={Workspace} code={"workspace-001"} />,
    code: "workspace-001",
  },

  {
    path: "/dashboards/upload-document",
    element: <Page component={NewDocument} code={"workspace-001"} />,
    code: "workspace-001",
  },
  {
    path: "/dashboards/upload-document/:id",
    element: <Page component={NewDocument} code={"workspace-001"} />,
    code: "workspace-001",
  },
  {
    path: "/dashboards/upload-document/:id/:category/:id",
    element: <Page component={NewDocument} code={"workspace-001"} />,
    code: "workspace-001",
  },

  {
    path: "/dashboards/new-container",
    element: <Page component={NewContainer} code={"workspace-001"} />,
    code: "workspace-001",
  },

  {
    path: "/dashboards/new-container/:id",
    element: <Page component={NewContainer} code={"workspace-001"} />,
    code: "workspace-001",
  },

  {
    path: "/dashboards/edit-container/:id",
    element: <Page component={NewContainer} code={"workspace-001"} />,
    code: "workspace-001",
  },
];

export default dashboardRoutes;
