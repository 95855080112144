import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  Card,
  useMediaQuery,
  Checkbox,
  Typography,
  TableCell,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import SortedTableItem from "./SortedTableItem";
import { users } from "../data";

import TablePagination from "@mui/material/TablePagination";
import FilesListItem from "./FileListItem";
import JumboTableHead from "./../../../../../../@jumbo/components/JumboTableHead/JumboTableHead";
import { useFetchAllFavoriteFiles } from "../../query/useFetchAllFavoriteFiles";
import JumboCustomLoader from "./../../../../../../@jumbo/components/JumboLoader/JumboCustomLoader";

function FilesList({ selectedUsers, onDocumentSelect, query }) {
  const [userList, setUserList] = useState(users);
  //   const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data: favoritesData,
    refetch,
    isLoading,
  } = useFetchAllFavoriteFiles(page + 1, limit, query);

  useEffect(() => {
    if (
      typeof query === "string" &&
      (query.trim() !== "" || query.trim() === "")
    ) {
      refetch();
    }
  }, [query]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refetch();
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
    refetch();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((user) => user.id);
      onDocumentSelect(newSelecteds);
      return;
    }
    onDocumentSelect([]);
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    onDocumentSelect(newSelected);
  };

  const isSelected = (id) => selectedUsers.indexOf(id) !== -1;

  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery("(min-width:768px)");

  return (
    <Table>
      <Card sx={{ mb: 1 }}>
        {" "}
        <TableHead>
          <TableRow>
            <JumboTableHead padding="checkbox">
              <Checkbox
              // indeterminate={
              //   selectedUsers.length > 0 &&
              //   selectedUsers.length < userList.length
              // }
              // checked={
              //   userList.length > 0 &&
              //   selectedUsers.length === userList.length
              // }
              // onChange={handleSelectAllClick}
              />
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{ width: isLargeScreen || isMediumScreen ? "40%" : "60%" }}
            >
              <TableSortLabel>
                NAME
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "20%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                FAVORITED
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{
                width: "20%",
                display:
                  isLargeScreen || isMediumScreen ? "table-cell" : "none",
              }}
            >
              <TableSortLabel>
                VERSION
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
            <JumboTableHead
              align="left"
              style={{ width: isLargeScreen || isMediumScreen ? "25%" : "40%" }}
            >
              <TableSortLabel>
                ACTIVITY
                <ArrowDropDownIcon className="ml-2 h-4 w-4 text-gray-500 dark:text-gray-400" />
              </TableSortLabel>
            </JumboTableHead>
          </TableRow>
        </TableHead>
      </Card>

      <TableBody>
        {isLoading ? (
          <JumboCustomLoader isList={true} isCustom={false} />
        ) : Array.isArray(favoritesData?.items) &&
          favoritesData?.items.length > 0 ? (
          favoritesData?.items?.map((user, index) => (
            <FilesListItem
              key={user.id}
              user={user}
              isSelected={isSelected(user.id)}
              handleCheckboxChange={handleCheckboxChange}
              refetch={refetch}
              // deleteUser={() => handleDeleteUser(user.id)}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={100}
              >
                <Typography>No files available</Typography>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={favoritesData?.meta.totalItems || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Table>
  );
}

export default FilesList;
