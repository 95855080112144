import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import FilesList from "./components/FilesList/FilesList";

function FavoriteFiles() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [query, setQuery] = useState("");

  const handleDocumentSelection = (selected) => {
    setSelectedUsers(selected);
  };

  const handleQuery = (query) => {
    setQuery(query);
  };
  return (
    <DashboardLayout
      selectedUsers={selectedUsers}
      isFolderView={false}
      handleQuery={handleQuery}
    >
      <FilesList
        selectedUsers={selectedUsers}
        onDocumentSelect={handleDocumentSelection}
        query={query}
      />
    </DashboardLayout>
  );
}

export default FavoriteFiles;
