import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "./../store";

const initialState = {
  signupFormData: {
    mobile: "",
    firstName: "",
    lastName: "",
    email: "",
  },
};

const signupFormSlice = createSlice({
  name: "signupForm",
  initialState,
  reducers: {
    setSignupFormData: (state, action) => {
      state.signupFormData = action.payload;
    },
  },
});

export const { setSignupFormData } = signupFormSlice.actions;

export const useSignupFormActions = () => {
  const dispatch = useAppDispatch();

  const setFormData = (formData) => {
    dispatch(setSignupFormData(formData));
  };

  return {
    setFormData,
  };
};

const { reducer } = signupFormSlice;

export default reducer;
