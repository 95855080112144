import client from "./client";
import API_URLS from "./endPoints";

async function login(req) {
  return client.post(API_URLS.LOGIN, req);
}

async function signup(req) {
  return client.post(API_URLS.SIGNUP, req);
}

async function checkEmail(req) {
  return client.post(API_URLS.CHECK_EMAIL, req);
}

async function fetchIndustry() {
  return client.get(`${API_URLS.INDUSTRY}`);
}

async function fetchIndustryDepartment(id) {
  return client.get(`${API_URLS.INDUSTRYDEPT}/${id}`);
}

async function fetchEmpStrength() {
  return client.get(`${API_URLS.EMP_STRENGTH}`);
}

async function workspace(req) {
  return client.post(API_URLS.WORKSPACE, req);
}

async function forgetPassword(req) {
  return client.post(API_URLS.FORGET_PASSWORD, req);
}

async function resetPassword(req) {
  return client.post(API_URLS.RESET_PASSWORD, req);
}

export {
  resetPassword,
  forgetPassword,
  workspace,
  login,
  signup,
  fetchIndustry,
  fetchIndustryDepartment,
  fetchEmpStrength,
  checkEmail,
};
