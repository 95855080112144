import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { ListItem, Grid, Divider, Box } from "@mui/material";
import DndWrapper from "./components/DndWrapper";
import { useFetchFolderById } from "../new-container/query/useFetchFolderById";
import JumboFormLabelTextField from "./../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import DynamicFormField from "./components/DynamicFormField/DynamicFormFile";
import Div from "./../../../../@jumbo/shared/Div/Div";
import JumboButton from "./../../../../@jumbo/components/JumboButton/JumboButton";
import useDocumentForm from "./hooks/useDocumentForm";
import { useDocumentApiAction } from "./query/useDocumentApiActions";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const initialValues = {
  fileId: "xyz",
  file: "",
  fileName: "",
  description: "",
  folderId: "",
  indexValues: [],
};

const UploadDoc = () => {
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const { location } = window;
  const { enqueueSnackbar } = useSnackbar();
  const { category } = useParams();
  const id = location.pathname?.split("/")?.[3]
    ? location.pathname?.split("/")?.[3]
    : "0";

  const versionName = location.pathname.split("/")[4]
    ? decodeURIComponent(location.pathname.split("/")[4])
    : "0";

  const selectedFileId = location.pathname?.split("/")?.[5]
    ? location.pathname?.split("/")?.[5]
    : "0";

  const { data: folderData } = useFetchFolderById(id, true);
  const { tryUploadDocument, tryAddVersions } = useDocumentApiAction();

  const filesName = acceptedFiles.map((file) => (
    <ListItem selected key={file.path} sx={{ width: "auto", mr: 1 }}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));

  const handleDynamicFieldChange = (fieldName, value, type = false) => {
    const fieldIndex = values.indexValues?.findIndex(
      (item) => item.name === fieldName
    );

    let newField = {};

    if (!type) {
      newField = { name: fieldName, value: value };
    } else {
      newField = {
        name: fieldName,
        value:
          fieldIndex != -1
            ? values.indexValues[fieldIndex].value.includes(value)
              ? values.indexValues[fieldIndex].value.filter((e) => e != value)
              : [...values.indexValues[fieldIndex].value, value]
            : [value],
      };
    }

    if (fieldIndex === -1) {
      setFieldValue("indexValues", [...values.indexValues, newField]);
    } else {
      const updatedIndexValues = [...values.indexValues];
      updatedIndexValues[fieldIndex] = newField;
      setFieldValue("indexValues", updatedIndexValues);
    }
  };

  const onSubmit = async (values) => {
    const timestamp = moment().format("YYYYMMDD_HHmmss");

    const requiredIndices = [];
    for (const each of folderData?.indexGroup) {
      for (const every of each?.indexes) {
        if (every?.required) {
          requiredIndices.push(every?.fieldName);
        }
      }
    }
    const filledIndices = values?.indexValues?.map((e) => e?.name);
    const allRequiredFieldsFilled = requiredIndices.every((e) =>
      filledIndices.includes(e)
    );
    if (!category && !allRequiredFieldsFilled) {
      enqueueSnackbar("Please fill mandatory details", {
        variant: "error",
      });
      return;
    }
    if (versionName !== "0") {
      const formData = new FormData();
      acceptedFiles?.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileId", selectedFileId);
      formData.append("fileName", versionName);
      formData.append("description", values.description);
      formData.append("folderId", id);
      formData.append("indexValues", JSON.stringify(values.indexValues));
      formData.append("checkInCheckOutStatus", false);

      try {
        await tryAddVersions(formData);
        console.log("Document upload successful");
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    } else {
      const formData = new FormData();
      acceptedFiles?.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileId", values.fileId);

      formData.append("fileName", `${values?.fileName}_${timestamp}`);
      // formData.append("fileName", values.fileName);
      formData.append("description", values.description);
      formData.append("folderId", id);
      formData.append("checkInCheckOutStatus", false);
      formData.append("indexValues", JSON.stringify(values.indexValues));

      try {
        await tryUploadDocument(formData);
        resetForm();
        console.log("Document upload successful");
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    }
  };

  const formik = useDocumentForm(onSubmit, {
    ...initialValues,
    fileName: category,
    description: " ",
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
    errors,
    resetForm,
  } = formik;

  console.log("errors", folderData?.indexGroup);

  return (
    <>
      <DndWrapper sx={{ currsor: "pointer" }}>
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <input {...getInputProps()} />
          <Typography variant={"body1"}>
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </div>
      </DndWrapper>
      <Typography
        sx={{
          color: "#000000",
          fontSize: { xs: "1.2rem", md: "16px" },
          fontWeight: 400,
        }}
      >
        Selected File
      </Typography>
      <List disablePadding sx={{ display: "flex", mb: 2 }}>
        {filesName}
      </List>
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        {!category ? (
          <>
            {" "}
            <Grid item xs={12} md={7} lg={7}>
              <JumboFormLabelTextField
                labelStyle={{
                  color: "#000000",
                  fontSize: { xs: "1.2rem", md: "16px" },
                  fontWeight: 400,
                }}
                label=" File Name"
                name="fileName"
                value={values.fileName}
                onBlur={handleBlur("fileName")}
                onChange={handleChange("fileName")}
                placeholder="Enter file name"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              <JumboFormLabelTextField
                labelStyle={{
                  color: "#000000",
                  fontSize: { xs: "1.2rem", md: "16px" },
                  fontWeight: 400,
                }}
                rows={6}
                maxRows={8}
                multiline
                label=" Description"
                name="description"
                value={values.description}
                onBlur={handleBlur("description")}
                onChange={handleChange("description")}
                placeholder="Add description here....."
                type="text"
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} md={7} lg={7}>
          {!category &&
            folderData?.indexGroup &&
            folderData?.indexGroup.map((each) => (
              <Div>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                >
                  {each?.groupTitle}
                </Typography>
                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                <Box pl={4}>
                  {each?.indexes?.map((index) => (
                    <DynamicFormField
                      key={index.id}
                      index={index}
                      onChange={handleDynamicFieldChange}
                    />
                  ))}
                </Box>
              </Div>
            ))}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <Div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <LoadingButton
              fullWidth
              disableElevation
              variant={"contained"}
              sx={{
                mb: 4,
                width: "144px",
                border: "1px solid #371B65",
                borderRadius: "12px",
                backgroundColor: " #371B65",
                fontFamily: "Roboto",
                fontSize: { lg: " 15px", md: "15px", xs: "12px" },
                fontWeight: 400,
                lineHeight: "31px",
                textAlign: "left",
                textTransform: "none",
              }}
              loading={isSubmitting}
              onClick={() => handleSubmit()}
            >
              Upload
            </LoadingButton>
          </Div>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadDoc;
