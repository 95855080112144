/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchAllFiles } from "../../../../services/pages.services";

import { QueryKeys } from "../../../../utils/QueryKeys";

async function getAllFiles(page, limit, query) {
  try {
    const response = await fetchAllFiles(page, limit, query);
    console.log("roles data", response);
    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchAllFiles = (page, limit, query, enabled = true) => {
  const cacheKey = [QueryKeys.document];
  return useQuery(
    cacheKey,
    () => {
      return getAllFiles(page, limit, query);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchAllFiles };
