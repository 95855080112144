import VerticalDefault from "./vertical-default/VerticalDefault";
import SoloPage from "./solo-page/SoloPage";
import PackagePage from "./package-page/PackagePage";

const LAYOUT_NAMES = {
  VERTICAL_DEFAULT: "vertical-default",
  SOLO_PAGE: "solo-page",
  PACKAGES_PAGE: "package-page",
};

const LAYOUTS = [
  {
    name: LAYOUT_NAMES.VERTICAL_DEFAULT,
    label: "Vertical Default",
    component: VerticalDefault,
    layoutOptions: {
      sidebar: {
        hide: false,
        variant: "persistent",
        style: "full-height",
        view: "full",
        scrollType: "fixed",
        anchor: "left",
      },
      header: {
        hide: false,
        fixed: true,
      },
      footer: {
        hide: false,
      },
    },
  },
  {
    name: LAYOUT_NAMES.SOLO_PAGE,
    label: "Solo Page",
    component: SoloPage,
    layoutOptions: {
      sidebar: {
        hide: true,
      },
      header: {
        hide: true,
      },
      footer: {
        hide: true,
      },
    },
  },
  {
    name: LAYOUT_NAMES.PACKAGES_PAGE,
    label: "Package Page",
    component: PackagePage,
    layoutOptions: {
      sidebar: {
        hide: true,
      },
      header: {
        hide: false,
        fixed: true,
      },
      footer: {
        hide: true,
      },
    },
  },
];

export default LAYOUT_NAMES;
export { LAYOUTS };
