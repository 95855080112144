import { useFormik } from "formik";
import * as Yup from "yup";

const defaultValues = {
  password: "",
  confirmPassword: "",
};

const schema = Yup.object().shape({
  password: Yup.string().required("Please enter a valid password"),
  confirmPassword: Yup.string().required("Password doesnot match"),
});

const useConfirmPasswordForm = (onSubmit, initialValues = defaultValues) => {
  return useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useConfirmPasswordForm;
