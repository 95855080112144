import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import PropTypes from "prop-types";

const JumboScrollbar = React.forwardRef((props, ref) => {
  const { theme } = useJumboTheme();
  const { direction, disable, ...restProps } = props;

  if (disable) {
    return restProps.children ?? null;
  }

  const renderTrackVertical = ({ style, ...props }) => (
    <div
      style={{
        ...style,
        top: "2px",
        bottom: "2px",
        right: "2px",
        borderRadius: "3px",
        ...(theme.direction === "rtl" ? { right: "auto", left: "2px" } : {}),
      }}
      {...props}
    />
  );

  const renderTrackHorizontal = ({ style, ...props }) => (
    <div
      style={{
        ...style,
        left: "2px",
        right: "2px",
        height: "6px",
        bottom: "2px",
        borderRadius: "3px",
      }}
      {...props}
    />
  );

  return (
    <Scrollbars
      renderView={(props) => (
        <div
          {...props}
          style={
            theme.direction === "rtl"
              ? { ...props.style, marginLeft: "-17px", marginRight: 0 }
              : { ...props.style }
          }
        />
      )}
      ref={ref}
      renderTrackVertical={renderTrackVertical}
      renderTrackHorizontal={renderTrackHorizontal}
      {...restProps}
    />
  );
});

JumboScrollbar.defaultProps = {
  direction: "vertical",
};

JumboScrollbar.propTypes = {
  direction: PropTypes.string,
  disable: PropTypes.bool,
};

export default JumboScrollbar;
