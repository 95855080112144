const ErrorMessages = {
  login: {
    email: "Please enter your email",
    password: "Please enter your password",
    account:"Please enter account",
    success: "Logged successful",
  },
  signup: {
    firstName: "Please enter your first Name",
    lastName: "Please enter your last Name",
    companyName: "Please enter your Company Name",
    companyDescription: "Please enter your Company Description",
    confirmPassword: "Password does not match",
  },
  workflow: {
    worflowDescription: "Please enter workflow description",
    workflowFile: "Please choose file",
    documentClasses: "Please select document classes",
  },
  document: {
    pid: "Please enter property Id",
    docId: "Please enter document Id",
    propCode: "Please enter property code ",
    propValue: "Please enter property value",
    propType: "Please enter property type",
    displayOrder: "Please enter display order",
  },
  user: {
    userName: "Please enter user name",
    email: "Please enter email",
    firstName: "Please enter first name",
    lastName: "Please enter last name",
    password: "Please enter password",
    phoneNumber: "Please enter phone number",
    rid: "Please enter user role Id",
    did: "Please enter user document Id",
    isActive: "Please select status",
    accountName: "Please enter account name",
  },
  container: {
    code: "Please enter code",
    name: "Please enter  name",
    parentId: "Please enter parentId",
  },

  group: {
    groupCode: "Please enter code",
    groupName: "Please enter  name",
    description: "Please enter description",
  },
  profile: {
    firstName: "Please enter your first name",
    lastName: "Please enter your last name",
    phoneNumber:"Please enter your phone number",
    email: "Please enter your email"
  }
};

export default ErrorMessages;
