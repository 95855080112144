import React from "react";
import ContentLoader from "react-content-loader";
import { BulletList } from 'react-content-loader';
import { List } from 'react-content-loader'

const CustomLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={400}
    height={100}
    viewBox="0 0 340 84"
    backgroundColor="#f3f3f3"
    // backgroundColor="red"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" /> 
    <rect x="127" y="48" rx="3" ry="6" width="53" height="11" /> 
    <rect x="187" y="48" rx="3" ry="8" width="72" height="11" /> 
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" /> 
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" /> 
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" /> 
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
  </ContentLoader>
);

const MyBulletListLoader = () => <BulletList />;


const MyListLoader = () => <List />

const JumboCustomLoader = ({isBullet, isList}) => (
  <>
    {isBullet &&  <CustomLoader />}
    {isBullet &&    <MyBulletListLoader />}
    {isList &&    <MyListLoader />}
 
  </>
);

JumboCustomLoader.defaultProps = {

  isCustom:true,
  isBullet:false,
  isList:false,

};

export default JumboCustomLoader;

