import React from "react";
import { Navigate } from "react-router-dom";
import dashboardRoutes from "./dashboardRoutes";
import Login2 from "../pages/auth-pages/login2";
import Signup2 from "../pages/auth-pages/signup2";
import Password from "../pages/auth-pages/signup2/components/PasswordForm";
import QuestionAir from "../pages/auth-pages/signup2/components/QuestionAirForm";
import Page from "@jumbo/shared/Page";
import Packages from "../pages/packages/Packages";
import ConfirmPassword from "../pages/auth-pages/login2/components/ConfirmPasswordPopup/ConfirmPasswordPopup";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: "/user/login",
    element: <Page component={Login2} layout={"solo-page"} />,
    code: "DEFAULT",
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  // {
  //   path: "/dashboards/misc",
  //   element: <Navigate to={"/dashboards/misc"} />,
  // },
  ...dashboardRoutes,
  // ...packagesRoutes,
  {
    path: "/packages",
    element: <Page component={Packages} layout={"package-page"} />,
  },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/",
    element: <Page component={Login2} layout={"solo-page"} code={"DEFAULT"} />,
    code: "DEFAULT",
  },
  {
    path: "/user/signup",
    // element: <Signup2 />,
    element: <Page component={Signup2} layout={"solo-page"} code={"DEFAULT"} />,
    code: "DEFAULT",
  },
  {
    path: "/user/password",
    // element: <Password />,
    element: (
      <Page component={Password} layout={"solo-page"} code={"DEFAULT"} />
    ),
    code: "DEFAULT",
  },

  {
    path: "/user/workspace/:id/:code",
    element: (
      <Page component={QuestionAir} layout={"solo-page"} code={"DEFAULT"} />
    ),
    code: "DEFAULT",
  },

  // {
  //   path: "/user/workspace",
  //
  // },
  {
    path: "/user/reset-password/:code",
    element: <Page component={ConfirmPassword} layout={"solo-page"} />,
    code: "DEFAULT",
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
