import React from 'react'
import { Typography } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import JumboCardQuick from './../../../../../../@jumbo/components/JumboCardQuick/JumboCardQuick';
import { Grid } from '@mui/material';
import { CardMedia } from '@mui/material';
import { Button } from '@mui/material';
// import { PlayCircleIcon } from '@mui/icons-material/PlayCircle';
import { getAssetPath } from './../../../../../utils/appHelpers';
import { ASSET_IMAGES } from './../../../../../utils/constants/paths';
import { Fab } from '@mui/material/Fab';
import { Stack } from '@mui/material';

function FrequentlyAsKQuestion() {
  return (
    <><Typography variant="h1" mb={3} display={"flex"} justifyContent={"center"}>Pricing FAQ</Typography>

<Grid container spacing={3.75}>
        <Grid item xs={12} lg={6}>
 <JumboCardQuick
            title={"Can I upgrade and add more users during a billing cycle?"}
            subheader={"A little flash back of Kiley Brown"}
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
            <Typography variant="h3" fontWeight="300" mb={2}>Yes, you can upgrade or add users anytime during the yearly billing cycle by contacting your account manager.</Typography>
            <Typography variant="body1" mb={2}>The cost will be pro-rated for the remaining days, so you will not be double-charged.</Typography>
        
        </JumboCardQuick>
        <JumboCardQuick
            title={"Do you offer support?"}
            // subheader={"A little flash back of Kiley Brown"}
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
            <Typography variant="h3" fontWeight="300" mb={2}>We provide full, online support and welcome your feedback and questions..</Typography>
            <Typography variant="body1" mb={2}>Please submit issues by using our support page or contact your account manager with any feedback or suggestions.</Typography>
           
        </JumboCardQuick>
        <JumboCardQuick
            title={"Are there any hidden fees or contracts?"}
            
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
            <Typography variant="h3" fontWeight="300" mb={2}>No</Typography>
           
        </JumboCardQuick>
        <JumboCardQuick
            title={"Can I cancel any time?"}
           
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
            <Typography variant="h3" fontWeight="300" mb={2}>Yes. Once you cancel your account, you'll be responsible for charges incurred for that billing period, but you won't be charged again.</Typography>
           
          
        </JumboCardQuick>
        </Grid>
        <Grid item xs={12} lg={6}>
 <JumboCardQuick
            title={"Do you offer enterprise-grade security and self-hosting options?"}
         
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
            <Typography variant="h3" fontWeight="300" mb={2}>Enterprise Plus, our top license tier includes all the cloud-based modeling and collaboration features of SqlDBM plus robust, enterprise-grade security. This includes customer-hosted and managed storage, IP access management, and audit logging to help meet rigorous security standards and data residency requirements. Discover more advantages of Enterprise Plus here.</Typography>
          
        </JumboCardQuick>
        <JumboCardQuick
            title={"Do you have cloud certifications? Can you complete my security & risk questionnaire? Where can I download more information?"}
           
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
            <Typography variant="h3" fontWeight="300" mb={2}>Yes, please contact your SqlDBM representative for next steps or email us at security@sqldbm.com</Typography>
         
        </JumboCardQuick>
        <JumboCardQuick
            title={"Have more questions?"}
            
            headerSx={{pb: 0}}
            sx={{mb: 3.75}}
        >
<Stack sx={{display:"flex", flexDirection:"column"}}><QuizIcon sx={{fontSize:"80px"}}/>

<Button variant={"contained"} sx={{mt:"30px", width:"156px"}}>Contact Us</Button></Stack>

           
        </JumboCardQuick>

        </Grid>
       
        </Grid>
   
      </>
  )
}

export default FrequentlyAsKQuestion