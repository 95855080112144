import React from "react";
import Div from "@jumbo/shared/Div";
import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import { ASSET_LOGOS } from "./../../utils/constants/paths";
import { useTheme, styled } from "@mui/material/styles";

const StyledImage = styled("img")(({ width, height }) => ({
  width,
  height,
}));

const AuthLayout = ({ children, title, description, isQuestion }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

  let width;
  let height = isQuestion ? "294px" : "226px";

  if (isQuestion) {
    if (isLargeScreen) {
      width = "293px";
    } else if (isMediumScreen) {
      width = "180px";
    }
  } else {
    if (isLargeScreen) {
      width = "293px";
    } else if (isMediumScreen) {
      width = "160px";
    }
  }
  return (
    <Div
      sx={{
        width: { lg: "943px", md: "100%px", xs: "100%" },
        height: { lg: "521px", md: "421px", xs: "320px" },
        maxWidth: "100%",
        borderRadius: "20px",
        p: { xs: 4, lg: 0, md: 2 },
        margin: { lg: "auto" },
      }}
    >
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
          boxShadow: "10px 4px 20px 10px #C0C0C040",
        }}
      >
        <CardContent
          sx={{
            backgroundSize: "cover",
            width: { lg: "408px", md: "80%", xs: "100%" },
            backgroundColor: "#5C38A4",
            p: { lg: 2, md: 4, xs: 4 },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: { lg: "521px", xs: "360px", md: "400px" },
              marginLeft: { md: 0 },
              alignItems: { md: "center" },
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: isQuestion
                  ? {
                      lg: "113px 62px 114px 52px",
                      md: "95px 40px 95px 30px",
                      xs: "75px 20px 75px 20px",
                    }
                  : {
                      lg: "61px 91px 0px 91px",
                      xs: "25px 30px 0px 30px",
                      md: "50px 81px 0px 50px",
                    },
              }}
            >
              <StyledImage
                src={`${ASSET_LOGOS}/docLogo.png`}
                alt="Jumbo React"
                width={width}
                height={height}
              />

              {/* <img
                src={`${ASSET_LOGOS}/docLogo.png`}
                alt="Jumbo React"
                style={{
                  width: isQuestion
                    ? { lg: "293px", md: "220px", xs: "220px" }
                    : "226px",
                  height: isQuestion ? "294px" : "226px",
                }}
              /> */}
            </Div>
            <Div
              sx={{
                mb: 2,

                padding: {
                  // xs: "0px 20px 20px 120px",
                  lg: "50px 37px 66px 59px",
                  md: "10px 10px 10px 20px",
                },
                display: isQuestion ? "none" : "flex",
                flexDirection: "column",

                margin: {
                  xs: "0 auto",
                  md: "0 auto",
                },
              }}
            >
              <Typography
                color={"inherit"}
                fontWeight={400}
                fontSize={{ lg: "28px", md: "20px", xs: "15px" }}
                lineHeight={"36px"}
                letterSpacing={"5%"}
                fontFamily={"Roboto"}
              >
                {title}
              </Typography>
              <Typography
                color="#C1BFBF"
                fontWeight={400}
                fontSize={{ lg: "18px", md: "15px", xs: "12px" }}
                lineHeight={"28px"}
                letterSpacing={"5%"}
                fontFamily={"Roboto"}
              >
                {description}
              </Typography>
            </Div>
          </Div>
        </CardContent>
        <CardContent
          sx={{ p: { lg: 4, md: 4, xs: 4 }, width: { md: "100%", xs: "100%" } }}
        >
          {children}
        </CardContent>
      </Card>
    </Div>
  );
};

export default AuthLayout;
