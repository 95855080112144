import VerticalDefault from "../../layouts/vertical-default/VerticalDefault";
import SoloPage from "../../layouts/solo-page/SoloPage";
import PackagePage from "./../../layouts/package-page/PackagePage";

const LAYOUT_NAMES = {
  VERTICAL_DEFAULT: "vertical-default",
  SOLO_PAGE: "solo-page",
  PACKAGE_PAGE: "package-page",
};

const LAYOUTS = [
  {
    name: LAYOUT_NAMES.VERTICAL_DEFAULT,
    label: "Vertical Default",
    component: VerticalDefault,
  },
  {
    name: LAYOUT_NAMES.SOLO_PAGE,
    label: "Solo Page",
    component: SoloPage,
  },
  {
    name: LAYOUT_NAMES.PACKAGE_PAGE,
    label: "Package Page",
    component: PackagePage,
  },
];

export { LAYOUTS, LAYOUT_NAMES };
