import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import DataModellingItem from "./DataModellingItem";
import {dataModelling} from "./dataMod";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const DataModellingList = () => {

    return (
       
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{pl: 3}}></StyledTableCell>
                        <StyledTableCell align={"center"}>Small Enterprises</StyledTableCell>
                      
                        <StyledTableCell sx={{pr: 2}} align={"center"}>Standard Enterprises</StyledTableCell>
                        <StyledTableCell sx={{pr: 2}} align={"center"}>Enterprise Plus</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        dataModelling.map((product, index) => (
                            <DataModellingItem item={product} key={index}/>
                        ))
                    }
                </TableBody>
            </Table>
      
    );
};

export default DataModellingList;