import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import {
  Grid,
  Tabs,
  Tab,
  Card,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import JumboButton from "@jumbo/components/JumboButton";
import JumboFormLabelTextField from "../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import React, { useState, useEffect } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useFetchFileById } from "./query/useFetchFileById";
import FileIcon from "./../workspace/components/FileIcon/index";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import useIndexPropForm from "./hooks/useIndexPropForm";
import DynamicFormField from "../upload-document/components/DynamicFormField/DynamicFormFile";

const initialValues = {
  fieldName: "",
  type: "",
  options: [],
};

function FileDetail() {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const { id, version } = useParams();

  console.log("id", id, version);

  const onSubmit = async (values) => {
    console.log("onSubmit", values);
    formik.resetForm();
  };

  const formik = useIndexPropForm(onSubmit, initialValues);

  const {
    handleBlur,
    setFieldValue,
    handleSubmit,
    values,
    errors,
    touched,
    handleChange,
    isSubmitting,
  } = formik;

  const {
    data: fileData,
    refetch,
    isLoading,
  } = useFetchFileById(id, version, false);
  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    if (fileData?.indexInputs?.length > 0) {
      fileData.indexInputs.forEach((input) => {
        setFieldValue("fieldName", input.fieldName);
        setFieldValue("type", input.type);

        // Parse the options if available
        if (input.options) {
          try {
            const parsedOptions = JSON.parse(input.options);
            setFieldValue("options", parsedOptions);
          } catch (error) {
            console.error("Failed to parse options:", error);
          }
        }
      });
    }
  }, [fileData?.indexInputs, setFieldValue]);

  console.log("data ", fileData);

  // Define tabs dynamically based on fileData.folder.isProject
  const tabs = [];

  if (fileData?.folder?.isProject) {
    tabs.push({ label: "Properties" });
  }

  tabs.push({ label: "Version" }, { label: "History" });

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const renderFile = () => {
    if (!fileData || !fileData.fileUrl?.fileUrl) return null;

    const fileURL = fileData.fileUrl?.fileUrl;
    const fileType = fileData.fileType;

    if (fileType.startsWith("image/jpeg") || fileType.startsWith("image/png")) {
      return (
        <img
          src={fileURL}
          alt="File"
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        // <iframe
        //   src={fileURL}
        //   style={{ width: "100%", height: "100%" }}
        // ></iframe>

        <object
          data={fileURL}
          type="application/pdf"
          style={{ width: "100%", height: "100%" }}
        >
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            Download the PDF
          </a>
        </object>
      );
    } else if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      fileType === "text/plain"
    ) {
      return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            fileURL
          )}&embedded=true`}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        ></iframe>
      );
    } else {
      return <p>Cannot preview this file type. Please download to view.</p>;
    }
  };

  const handleGoToFile = (id) => {
    navigate(`/dashboards/file-details/${id}/1`);
  };

  if (!fileData) {
    return <div>File not found</div>;
  }

  console.log(fileData);

  return (
    <DashboardLayout
      isFolderView={false}
      isFileDetail={true}
      isLoading={isLoading}
    >
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        <Grid item xs={12} md={12} lg={8}>
          <Card
            sx={{
              padding: { xs: "1rem", sm: "1rem", md: "0px" },
              mr: "22px",
              border: "1px solid #FCFBF6",
              backgroundColor: "#FCFBF6",
              position: "relative",
              borderRadius: "11px",
              height: "100%",
            }}
          >
            {renderFile()}
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Card sx={{ borderRadius: "8px" }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="file detail tabs"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#371B65",
                  color: "#371B65",
                },
              }}
              variant="fullWidth"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontSize: "13.12px",
                    fontWeight: 600,
                    lineHeight: "18.95px",
                    color: "#2E4448",
                  }}
                  label={tab.label}
                />
              ))}
            </Tabs>
          </Card>
          <Card
            borderRadius="11px"
            sx={{
              padding: "27px 60px 20px 28px",
              border: "1px solid #FCFBF6",
              backgroundColor: "#FFFFFF",
              gap: "0px 24px",
              alignItems: "center",
            }}
          >
            {tabs.map((tab, index) => {
              if (tabIndex !== index) return null;

              switch (tab.label) {
                case "Properties":
                  return (
                    <React.Fragment key={index}>
                      {/* <JumboFormLabelTextField
                        fullWidth
                        labelStyle={{
                          fontFamily: "Inter",
                          color: "#000000",
                          fontSize: { xs: "1.2rem", md: "16px" },
                          fontWeight: 400,
                        }}
                        label="Field Name"
                        name="fieldName"
                        placeholder="Enter Field Name "
                        type="text"
                        value={values.fieldName}
                        onBlur={handleBlur("fieldName")}
                        onChange={handleChange("fieldName")}
                      />
                      <JumboFormLabelTextField
                        fullWidth
                        labelStyle={{
                          fontFamily: "Inter",
                          color: "#000000",
                          fontSize: { xs: "1.2rem", md: "16px" },
                          fontWeight: 400,
                        }}
                        label="type"
                        name="type"
                        placeholder="Enter Field Type"
                        type="text"
                        value={values.type}
                        onBlur={handleBlur("type")}
                        onChange={handleChange("type")}
                        defaultValue={fileData.type}
                      />

                      <JumboFormLabelTextField
                        isSelect
                        fullWidth
                        labelStyle={{
                          fontFamily: "Inter",
                          color: "#000000",
                          fontSize: { xs: "1.2rem", md: "16px" },
                          fontWeight: 400,
                        }}
                        label="Field Options"
                        name="fieldOptions"
                        placeholder="Enter Field Options"
                        // type="text"
                        defaultValue={fileData.fieldOptions}
                      /> */}
                      {fileData?.indexInputs?.map((each) => (
                        <DynamicFormField
                          key={each.id}
                          index={each}
                          onChange={(...h) => {}}
                        />
                      ))}

                      <Box
                        sx={{
                          mt: 1,
                          ml: 0,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          justifyContent: "end",
                        }}
                      >
                        <JumboButton
                          sx={{
                            padding: "8px 16px",
                            gap: "8px",
                            borderRadius: "4px",
                            border: "1px solid #371B65",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            color: "#371B65",
                            mr: "10px",
                            textTransform: "none",
                          }}
                          variant="outlined"
                        >
                          Reset
                        </JumboButton>
                        <JumboButton
                          sx={{
                            textTransform: "none",
                            padding: "8px 16px",
                            gap: "8px",
                            borderRadius: "4px",
                            border: "1px solid #371B65",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            backgroundColor: "#371B65",
                            color: "#FCFBF6",
                          }}
                          variant="contained"
                        >
                          Save
                        </JumboButton>
                      </Box>
                    </React.Fragment>
                  );

                case "Version":
                  return fileData?.versions && fileData.versions.length > 0 ? (
                    // fileData.chkInChkOutHistory.map((data, hIndex) => (
                    fileData?.versions?.map((version, vIndex) => (
                      <div key={vIndex}>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            p: 2,
                            bgcolor: "background.paper",
                          }}
                        >
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="Icon" />
                              </Grid>
                              <Grid item xs={8}>
                                <FileIcon type={version.fileType} />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="File Name" />
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleGoToFile(version.id)}
                              >
                                <ListItemText
                                  primary={version.fileName}
                                  primaryTypographyProps={{
                                    sx: {
                                      color: "blue",
                                      textDecoration: "underline",
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="Version no" />
                              </Grid>
                              <Grid item xs={8}>
                                <ListItemText primary={version.version} />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="Date" />
                              </Grid>
                              <Grid item xs={8}>
                                <ListItemText
                                  primary={moment(version?.createdAt).format(
                                    "DD/MM/YYYY h:mm A"
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="Time" />
                              </Grid>
                              <Grid item xs={8}>
                                <ListItemText
                                  primary={moment(version?.updatedAt).format(
                                    "DD/MM/YYYY h:mm A"
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </div>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ justifyContent: "center", p: 2 }}
                    >
                      No version available
                    </Typography>
                  );

                case "History":
                  return fileData?.chkInChkOutHistory &&
                    fileData.chkInChkOutHistory.length > 0 ? (
                    fileData.chkInChkOutHistory.map((data, hIndex) => (
                      <React.Fragment key={hIndex}>
                        <div>
                          <List
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              p: 2,
                              bgcolor: "background.paper",
                            }}
                          >
                            <ListItem disableGutters>
                              <Grid container alignItems="center">
                                <Grid item xs={4}>
                                  <ListItemText primary="User Name" />
                                </Grid>
                                <Grid item xs={8}>
                                  <ListItemText primary={data.UserName} />
                                </Grid>
                              </Grid>
                            </ListItem>
                            <ListItem disableGutters>
                              <Grid container alignItems="center">
                                <Grid item xs={4}>
                                  <ListItemText primary="Status" />
                                </Grid>
                                <Grid item xs={8}>
                                  <ListItemText
                                    primary={data.checkInCheckOutStatus}
                                  />
                                </Grid>
                              </Grid>
                            </ListItem>
                            <ListItem disableGutters>
                              <Grid container alignItems="center">
                                <Grid item xs={4}>
                                  <ListItemText primary="Time" />
                                </Grid>
                                <Grid item xs={8}>
                                  <ListItemText
                                    primary={moment(data?.createdAt).format(
                                      "DD/MM/YYYY h:mm A"
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </List>
                        </div>
                        <Divider />
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ justifyContent: "center", p: 2 }}
                    >
                      No history available
                    </Typography>
                  );

                default:
                  return null;
              }
            })}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default FileDetail;
