import React from "react";
import { Typography, Box } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import { getAssetPath } from "./../../../utils/appHelpers";
import FileIcon from "./../../../pages/dashboards/workspace/components/FileIcon/index";
import Div from "./../../../../@jumbo/shared/Div/Div";
import { useDashboardApiAction } from "app/pages/dashboards/misc/query/useDashboardApiAction";

const UserOrders = ({ data, handlePinned, refetch, handleGoToFile }) => {
  const { t } = useTranslation();
  const { tryPinnedFile } = useDashboardApiAction();
  console.log("UserOrders", data);
  const fileSizeInMB = (data?.file?.size / (1024 * 1024)).toFixed(2);

  const handlePinnedSet = async () => {
    console.log("Image clicked, handling pin...");
    try {
      await tryPinnedFile({ fileId: data?.file?.id });
      handlePinned();
      refetch();
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  return (
    <JumboCardQuick
      noWrapper
      sx={{
        height: "120px",
        borderRadius: "12px",
        textAlign: "center",
        cursor: "pointer",
      }}
      onClick={() => handleGoToFile(data?.file?.id)}
      title={
        <>
          <Div sx={{ display: "flex", justifyContent: "center" }}>
            <FileIcon type={data?.file?.fileType} />
          </Div>

          <img
            onClick={handlePinnedSet}
            src={getAssetPath(`${ASSET_IMAGES}/pin-bold.png`, "24x24")}
            alt="pin"
            width={24}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          />
          <Typography
            sx={{
              color: " #000000",
              fontFamily: "Roboto",
              fontSize: { lg: "14px", md: "12px", xs: "10px" },
              fontWeight: 500,
              lineHeight: "31px",
            }}
          >
            {data?.file?.fileName}
          </Typography>
        </>
      }
      subheader={
        <Typography variant={"h6"} color={"text.secondary"} mb={0}>
          {fileSizeInMB} MB
        </Typography>
      }
    ></JumboCardQuick>
  );
};

export default UserOrders;
